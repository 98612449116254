<template>
  <div>
    <Map
      @tripCreated="tripCreated"
      @refreshMap="handleRefreshMap()"
      :refreshTable="refreshTable"
    />
    <br />
    <Table
      :trip="trip"
      :refreshMap="refreshMap"
      @refreshTable="handleRefreshTable()"
    />
  </div>
</template>

<script>
import Map from "./components/Map/Map.vue";
import Table from "./components/Table/Table.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Map,
    Table,
  },
  data() {
    return {
      trip: false,
      refreshMap: false,
      refreshTable: false,
    };
  },
  methods: {
    ...mapActions("auth", ["me", "meNotLoading"]),
    tripCreated() {
      this.trip = !this.trip;
      this.meNotLoading()
    },
    handleRefreshMap() {
      this.refreshMap = !this.refreshMap;
      this.meNotLoading()
    },
    handleRefreshTable() {
      this.refreshTable = !this.refreshTable;
      this.meNotLoading()
    },
  },
  computed: {
    ...mapState(["stateDriverCancelledEvent"]),
  },
  watch: {
    stateDriverCancelledEvent: function () {
      this.handleRefreshMap();
      this.handleRefreshTable();
    },
  },
  async created() {
    await this.me();
  },
};
</script>
