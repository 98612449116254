<template>
  <div
    class="d-flex flex-column justify-center align-center"
    :style="computedHeight"
  >
    <GmapMap
      :center="centerBase"
      ref="mapRef"
      :zoom="15"
      :style="computedHeight"
      :clickable="true"
      :draggable="true"
      :zoomControl="true"
      :options="computedOptions"
      v-show="loadingMap"
    >
      <!-- :options="{ streetViewControl: false }" -->
      <!-- Origen -->
      <GmapMarker
        ref="origin"
        v-if="formOpen && stateOriginAddressMap"
        :position="originMarker.position && originMarker.position"
        @click="originMarker.position && (center = originMarker.position)"
        :animation="2"
        :draggable="iconsDraggable"
        @dragend="showLocationOrigin"
        :icon="markerOptionsOrigin"
      />
      <!-- Destino -->
      <GmapMarker
        ref="destination"
        v-if="formOpen && stateDestinationAddressMap"
        :position="destinationMarker.position && destinationMarker.position"
        @click="
          destinationMarker.position && (center = destinationMarker.position)
        "
        :animation="2"
        :draggable="iconsDraggable"
        @dragend="showLocationDestination"
        :icon="markerOptionsDestination"
      />
      <!-- Polyline hacia origen y destino -->
      <GmapPolyline
        ref="polyLine"
        v-if="formOpen && stateOriginAddressMap && stateDestinationAddressMap"
        :path="polylinePath"
        :options="polylineOptions"
      />
      <!-- Carritos cerca de Origen segun tipo de servicio -->
      <span v-if="formOpen && stateServiceTypeOrigin">
        <!-- <GmapMarker
          v-for="car in cars"
          :key="car.index"
          v-show="stateServiceTypeOrigin"
          :position="{ lat: car.lat, lng: car.lng }"
          :animation="2"
          :icon="markerOptionsCarTest"
        /> -->
        <GmapCustomMarker
          v-for="car in cars"
          :key="car.index"
          v-show="stateServiceTypeOrigin"
          :marker="{ lat: car.lat, lng: car.lng }"
        >
          <img
            :src="markerOptionsCarTest.url"
            alt=""
            :style="`transform: rotate(${car.bearing}deg);`"
          />
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <img
                :src="markerOptionsCarTest.url"
                alt=""
                :style="`transform: rotate(${car.bearing}deg);`"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <span>Top tooltip</span>
          </v-tooltip> -->
        </GmapCustomMarker>
      </span>
      <!-- Viajes andando -->
      <span v-if="!formOpen && computedLiveTrips.length > 0">
        <span v-for="trip in computedLiveTrips" :key="trip._id">
          <!-- provider -->
          <GmapCustomMarker
            :marker="{
              lat: trip.providerLocation[0],
              lng: trip.providerLocation[1],
            }"
            v-if="
              trip.providerLocation.length > 0 && computedLiveTrips.length > 0
            "
            @click.native="handleLiveTripsDetail(trip._id)"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <img
                  :src="markerOptionsCarTest.url"
                  alt=""
                  :style="`transform: rotate(${trip.bearing}deg);`"
                  v-bind="attrs"
                  v-on="on"
                  v-if="computedLiveTrips.length > 0"
                />
              </template>
              <span>
                <ul>
                  <li>{{ $t("Trip ID") }}: {{ trip.unique_id }}</li>
                  <li v-if="trip.is_provider_status === 6">
                    {{ $t("User") }}:
                    {{ trip.user_first_name + " " + trip.user_last_name }}
                  </li>
                  <li>
                    {{ $t("Provider") }}:
                    {{
                      trip.provider_first_name + " " + trip.provider_last_name
                    }}
                  </li>
                  <li>
                    Status:
                    {{
                      trip.status !== undefined
                        ? $t(trip.trip_status)
                        : $t("Started")
                    }}
                  </li>
                  <li>{{ $t("Service") }}: {{ trip.typename }}</li>
                </ul>
              </span>
            </v-tooltip>
          </GmapCustomMarker>
          <!-- user -->
          <GmapCustomMarker
            :marker="{
              lat: trip.sourceLocation[0],
              lng: trip.sourceLocation[1],
            }"
            v-if="
              trip.trip_status === 'Accepted' ||
              trip.trip_status === 'Coming' ||
              trip.trip_status === 'Waiting' ||
              trip.trip_status === 'Arrived'
            "
            @click.native="handleLiveTripsDetail(trip._id)"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  class="avatar-image"
                  :color="!$vuetify.theme.isDark ? 'info' : 'success'"
                  size="40"
                  v-if="computedLiveTrips.length > 0"
                >
                  <span class="white--text text-h6">{{
                    trip.user_first_name[0] + trip.user_last_name[0]
                  }}</span>
                </v-avatar>
              </template>
              <span>
                <ul>
                  <li>{{ $t("Trip ID") }}: {{ trip.unique_id }}</li>
                  <li>
                    {{ $t("User") }}:
                    {{ trip.user_first_name + " " + trip.user_last_name }}
                  </li>
                  <li>Status: {{ $t(trip.trip_status) }}</li>
                  <li>{{ $t("Service") }}: {{ trip.typename }}</li>
                </ul>
              </span>
            </v-tooltip>
          </GmapCustomMarker>
        </span>
      </span>
    </GmapMap>

    <v-progress-circular
      v-if="!loadingMap"
      indeterminate
      :size="64"
      color="primary"
    />
  </div>
</template>

<script>
import { mdiMapMarker, mdiAccount } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import originIcon from "../../../assets/IconOriginMapMap.png";
import destinationIcon from "../../../assets/IconDestinationMapMap.png";
import { gmapApi } from "vue2-google-maps";
import ellasxellas from "../../../assets/ELLASxELLAS.png";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import nightMap from "../../../assets/nightMap.js";
import dayMap from "../../../assets/dayMap.js";
import axios from "@/plugins/axios";

export default {
  name: "GoogleMap",
  components: {
    GmapCustomMarker,
  },
  props: {
    formOpen: {
      type: Boolean,
      default: false,
    },
    refreshMap: {
      type: Boolean,
      default: false,
    },
    refreshTable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      icons: {
        mdiMapMarker,
        mdiAccount,
      },
      //Ridery UniAuto
      centerBase: { lat: "", lng: "" },
      centerCabled: { lat: 10.5041952, lng: -66.8858767 },
      centerOrigin: {},
      centerDestination: {},

      markerOptionsOrigin: {
        url: originIcon,
      },
      markerOptionsDestination: {
        url: destinationIcon,
      },
      markerOptionsCarTest: {
        url: ellasxellas,
      },
      originMarker: null,
      destinationMarker: null,
      address: null,
      cars: null,
      loadingMap: false,
      liveTrips: [],
      polylineOptions: {
        strokeColor: "#FA5F55",
        strokeOpacity: 0,
        geodesic: true,
        strokeWeight: 10,
        icons: [
          {
            icon: {
              path: "M3.5 16C3.5 16.2761 3.72386 16.5 4 16.5C4.27614 16.5 4.5 16.2761 4.5 16L3.5 16ZM4.35355 0.646446C4.15829 0.451184 3.84171 0.451184 3.64645 0.646446L0.464466 3.82843C0.269203 4.02369 0.269203 4.34027 0.464466 4.53553C0.659728 4.7308 0.97631 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.73079 7.34027 4.73079 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646446ZM4.5 16L4.5 0.999999L3.5 0.999999L3.5 16L4.5 16Z",
              // path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
              // path: "M 0,-1 0,1",

              strokeOpacity: 1,
              strokeWeight: 2.5,
              scale: 1.5,
            },
            offset: 0,
            repeat: "35px",
          },
        ],
      },
      destinyPolyline: {},
    };
  },
  methods: {
    ...mapActions(["setLoading", "setBase", "setOrigin", "setDestination"]),

    handleLocationInitial() {
      const success = (position) => {
        // console.log(typeof(parseFloat(position.coords.latitude)))
        if (position) {
          this.centerBase.lat = parseFloat(position.coords.latitude);
          this.centerBase.lng = parseFloat(position.coords.longitude);
          this.setBase(this.centerBase);
        } else {
          this.centerBase = this.centerCabled;
        }
        // console.log(this.centerBase);
      };

      const error = (err) => {
        switch (err.code) {
          case 1:
            // PERMISSION_DENIED
            this.$dialog.error({
              text: "Para una mejor experiencia, por favor active la geolocalización",
              title: "Geo Localización Desactivada",
              actions: false,
            });
            this.centerBase = this.centerCabled;
            this.setBase(this.centerCabled);
            break;
          case 2:
            //POSITION_UNAVAILABLE
            this.$dialog.error({
              text: "Para una mejor experiencia, por favor intente nuevamente",
              title: "Geo Localización No Disponible",
              actions: false,
            });
            this.centerBase = this.centerCabled;
            this.setBase(this.centerCabled);
            break;

          case 3:
            // TIMEOUT
            this.$dialog.error({
              text: "Para una mejor experiencia, por favor intente nuevamente",
              title: "No fue posible activar Geo Localización",
              actions: false,
            });
            this.centerBase = this.centerCabled;
            this.setBase(this.centerCabled);
            break;

          default:
            this.centerBase = this.centerCabled;
            this.setBase(this.centerCabled);
            break;
        }
      };

      // This will open permission popup
      navigator.geolocation.getCurrentPosition(success, error);
    },
    showLocationOrigin(event) {
      this.$refs.origin.position.lat = event.latLng.lat();
      this.$refs.origin.position.lng = event.latLng.lng();

      let initialLocation = new this.google.maps.LatLng(
        event.latLng.lat(),
        event.latLng.lng()
      );
      let self = this;
      let geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ latLng: initialLocation }, async (results, status) => {
        try {
          self.setLoading(true);
          if (status == (await google.maps.GeocoderStatus.OK)) {
            let address = results[0];
            !!address && (self.address = address);
            if (self.address) {
              self.setOrigin(self.address);
              self.address = null;
            }
          }
          self.setLoading(false);
        } catch (error) {
          console.log(error);
        }
      });
    },
    showLocationDestination(event) {
      this.$refs.destination.position.lat = event.latLng.lat();
      this.$refs.destination.position.lng = event.latLng.lng();
      this.destinyPolyline["lat"] = event.latLng.lat();
      this.destinyPolyline["lng"] = event.latLng.lng();

      let initialLocation = new this.google.maps.LatLng(
        event.latLng.lat(),
        event.latLng.lng()
      );
      let self = this;
      let geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ latLng: initialLocation }, async (results, status) => {
        try {
          self.setLoading(true);
          if (status == (await google.maps.GeocoderStatus.OK)) {
            let address = results[0];
            !!address && (self.address = address);
            if (self.address) {
              self.setDestination(self.address);
              self.address = null;
            }
          }
          self.setLoading(false);
        } catch (error) {
          console.log(error);
        }
      });
    },
    async getLiveTrips() {
      this.setLoading(true);
      try {
        const { data } = await axios.get("/live_trips", {params: {only_shipments: 1}});
        if (!!data.success) {
          if (data.trips.length > 0) {
            this.liveTrips = [];
            data.trips.forEach((trip) => {
              this.liveTrips.push(trip);
            });
          }
        } else {
          throw new Error("Error recibiendo envíos en curso en el mapa");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    handleLiveTripsDetail(id) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        let route = this.$router.resolve({
          name: "ViewMap",
          params: { id },
        });
        window.open(route.href, "_blank").focus();
      }
    },
  },
  computed: {
    ...mapState([
      "stateBaseLocationMap",
      "stateOriginAddressMap",
      "stateDestinationAddressMap",
      "stateServiceTypeOrigin",
      "iconsDraggable",
    ]),
    google: gmapApi,
    computedOptions() {
      if (this.$vuetify.theme.isDark) {
        return {
          streetViewControl: false,
          styles: nightMap,
        };
      }
      if (!this.$vuetify.theme.isDark) {
        return { streetViewControl: false, styles: [] };
      }
    },
    computedHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return !this.formOpen
          ? "width: 100%; height: 400px"
          : "width: 100%; height: 562px";
      } else {
        return "width: 100%; height: 500px";
      }
    },
    computedLiveTrips() {
      return this.liveTrips;
    },
    polylinePath() {
      if (this.stateOriginAddressMap && this.stateDestinationAddressMap) {
        let coordsArray = [];
        coordsArray.push({
          lat: this.$refs.origin.position.lat,
          lng: this.$refs.origin.position.lng,
        });
        coordsArray.push({
          lat: this.destinyPolyline.lat,
          lng: this.destinyPolyline.lng,
        });
        return coordsArray;
      }
    },
    computedDestiny() {
      return this.$refs.destination.position;
    },
  },
  watch: {
    stateOriginAddressMap: async function (newVal, OldVal) {
      if (this.stateOriginAddressMap && newVal) {
        const marker = {
          lat: this.stateOriginAddressMap.geometry.location.lat(),
          lng: this.stateOriginAddressMap.geometry.location.lng(),
        };
        if (!OldVal) {
          this.originMarker = { position: marker };
        }
        this.centerOrigin.lat = marker.lat;
        this.centerOrigin.lng = marker.lng;
        this.$refs.mapRef.panTo(this.centerOrigin);
        if (this.stateOriginAddressMap && this.stateDestinationAddressMap) {
          await new Promise((r) => setTimeout(r, 1200));
          let bounding = new this.google.maps.LatLngBounds();
          bounding.extend({
            lat: this.stateOriginAddressMap.geometry.location.lat(),
            lng: this.stateOriginAddressMap.geometry.location.lng(),
          });
          bounding.extend({
            lat: this.stateDestinationAddressMap.geometry.location.lat(),
            lng: this.stateDestinationAddressMap.geometry.location.lng(),
          });
          this.$refs.mapRef.fitBounds(bounding);
        }
      }
    },
    stateDestinationAddressMap: async function (newVal, OldVal) {
      if (this.stateDestinationAddressMap) {
        const marker = {
          lat: this.stateDestinationAddressMap.geometry.location.lat(),
          lng: this.stateDestinationAddressMap.geometry.location.lng(),
        };
        if (!OldVal) {
          this.destinationMarker = { position: marker };
          this.destinyPolyline["lat"] = marker.lat;
          this.destinyPolyline["lng"] = marker.lng;
        }
        this.centerDestination.lat = marker.lat;
        this.centerDestination.lng = marker.lng;
        this.$refs.mapRef.panTo(this.centerDestination);
        if (this.stateOriginAddressMap && this.stateDestinationAddressMap) {
          await new Promise((r) => setTimeout(r, 1200));
          let bounding = new this.google.maps.LatLngBounds();
          bounding.extend({
            lat: this.stateOriginAddressMap.geometry.location.lat(),
            lng: this.stateOriginAddressMap.geometry.location.lng(),
          });
          bounding.extend({
            lat: this.stateDestinationAddressMap.geometry.location.lat(),
            lng: this.stateDestinationAddressMap.geometry.location.lng(),
          });
          this.$refs.mapRef.fitBounds(bounding);
        }
      }
    },
    stateServiceTypeOrigin: function () {
      if (this.stateServiceTypeOrigin) {
        this.cars = this.stateServiceTypeOrigin;
      } else {
        this.cars = null;
      }
    },
    refreshMap: function () {
      this.liveTrips = [];
      this.getLiveTrips();
    },
    refreshTable: function () {
      this.liveTrips = [];
      this.getLiveTrips();
    },
  },
  mounted() {
    if (this.$refs.mapRef) {
      this.$refs.mapRef.$mapPromise.then(() => {
        this.loadingMap = !this.loadingMap;
      });
    }
  },
  created() {
    if (!this.loadingMap) {
      this.handleLocationInitial();
      this.getLiveTrips();
    }
  },
};
</script>
