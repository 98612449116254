<template>
  <v-col
    v-if="formOpen"
    md="4"
    cols="12"
    :class="$vuetify.breakpoint.mdAndUp && 'pl-4'"
    id="form_"
    class=""
  >
    <v-card
      class="py-6 pa-5 d-flex flex-column"
      :height="$vuetify.breakpoint.mdAndUp ? '659.7px' : '40rem'"
      ref="form_"
      style="overflow-y: auto"
    >
      <span style="height: 100%">
        <ValidationObserver v-slot="{ invalid }">
          <v-form
            spellcheck="false"
            class="d-flex flex-column justify-space-between"
            style="height: 100%"
          >
            <span>
              <!-- Título y Close -->
              <v-row dense class="mb-6">
                <v-col
                  cols="12"
                  class="d-flex align-center justify-space-between"
                >
                  <span class="d-flex flex-row align-center">
                    <v-icon
                      medium
                      class="color-gray-text"
                      :style="handleColorClose"
                      @click.stop="handleClick()"
                    >
                      {{ icons.mdiWindowClose }}
                    </v-icon>
                    <h3 class="ml-6">
                      {{
                        createTripForm.tripType !== "Quick quote"
                          ? $t("Create a new Parcel")
                          : $t("Quick quote")
                      }}
                    </h3>
                    <v-tooltip bottom :max-width="300" v-if="!false">
                      <template #activator="{ on, attrs }">
                        <v-icon
                          medium
                          v-bind="attrs"
                          v-on="on"
                          class="color-gray-text ml-4"
                          :style="handleColorClose"
                        >
                          {{ icons.mdiInformationOutline }}
                        </v-icon>
                      </template>
                      <span v-if="formOpen">
                        <ul>
                          <li>
                            {{
                              $t(
                                "In this form you can create a new parcel for now or scheduled"
                              )
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "You can create parcel for your corporate users or for third-party users"
                              )
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "If you need to adjust coordinates for a parcel you can use the source and destination pointers for that purpose"
                              )
                            }}
                          </li>
                          <li>
                            {{ $t("You can also make quick quotes") }}
                          </li>
                        </ul>
                      </span>
                    </v-tooltip>
                  </span>
                  <router-link
                    :to="{ name: 'ParcelGuide' }"
                    target="_blank"
                    class="ml-2"
                    v-if="false"
                  >
                    {{ $t("parcel_more_info") }}
                  </router-link>
                </v-col>
              </v-row>

              <!-- Formulario -->

              <!-- paso 1 -->
              <!-- datos basicos -->
              <ValidationObserver>
                <template v-if="step === 1">
                  <!-- tipo de viaje -->
                  <v-row dense>
                    <!-- <code>{{ createTripForm.serviceType }}</code> -->
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('Parcel Type')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-select
                          :items="createTripForm.tripTypeOptions"
                          :label="$t('Parcel Type')"
                          outlined
                          dense
                          v-model="createTripForm.tripType"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                        >
                          <!-- @change="createTripForm.serviceType = null" -->
                          <template v-slot:item="{ item }">
                            <div>
                              {{ $t(item) }}
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div>
                              {{ $t(item) }}
                            </div>
                          </template>
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- date picker -->
                  <v-row
                    dense
                    class="margin"
                    v-if="
                      createTripForm.tripType === 'Scheduled' ||
                      createTripForm.tripType === 'Quick quote'
                    "
                  >
                    <v-col cols="12">
                      <!-- <code>{{ dateTimePickerProps.datetime }}</code> -->

                      <ValidationProvider
                        :name="$t('Date and Time')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-datetime-picker
                          :label="$t('Date and Time')"
                          v-model="dateTimePickerProps.datetime"
                          dense
                          clearText="Limpiar"
                          okText="Aceptar"
                          :text-field-props="dateTimePickerProps.textFieldProps"
                          :date-picker-props="dateTimePickerProps.dateProps"
                          :time-picker-props="dateTimePickerProps.timeProps"
                          time-format="h:mm a"
                          dateFormat="dd-MM-yyyy"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                        >
                          <template slot="dateIcon">
                            <v-icon>{{ icons.mdiCalendar }}</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>{{ icons.mdiClockOutline }}</v-icon>
                          </template>
                          <template slot="actions" slot-scope="{ parent }">
                            <span
                              class="mt-5 d-flex flex-row justify-center"
                              style="width: 100%"
                            >
                              <v-btn
                                class="ml-3"
                                color="primary"
                                outlined
                                @click.native="parent.clearHandler"
                                >{{ $t("Clear") }}
                              </v-btn>
                              <v-btn
                                color="primary"
                                class="ml-4"
                                @click="parent.okHandler"
                                >{{ $t("Accept") }}
                              </v-btn>
                            </span>
                          </template>
                        </v-datetime-picker>
                      </ValidationProvider>
                      <!-- <code>{{dateTimePickerProps.datetime}}</code>
                  <v-text-field type="datetime-local" v-model="dateTimePickerProps.datetime" name="partydate" outlined dense></v-text-field> -->
                    </v-col>
                  </v-row>
                  <!-- punto de origen -->
                  <!-- <code>{{ createTripForm.originAddress }}</code> -->
                  <!-- <br /> -->
                  <!-- <code>{{ stateOriginAddressMap }}</code> -->
                  <v-row dense class="margin">
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('Origin address')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <vuetify-google-autocomplete
                          id="origin"
                          classname="form-control"
                          :label="
                            createTripForm.originAddress &&
                            createTripForm.originAddress.length > 0 &&
                            $t('Origin address')
                          "
                          :placeholder="$t('Origin address')"
                          country="ve"
                          outlined
                          dense
                          clearable
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          v-model="createTripForm.originAddress"
                          @placechanged="setPlaceOrigin"
                          :enable-geolocation="true"
                          types=""
                        >
                          <!-- types="(locations)" -->
                          <template v-slot:prepend-inner>
                            <div>
                              <v-img
                                :src="
                                  require('../../../assets/IconOriginMap.png')
                                "
                              ></v-img>
                            </div>
                          </template>
                        </vuetify-google-autocomplete>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- punto de destino -->
                  <!-- code -->
                  <v-row dense class="margin">
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('Destination address')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <vuetify-google-autocomplete
                          id="destination"
                          classname="form-control"
                          :label="
                            createTripForm.destinationAddress &&
                            createTripForm.destinationAddress.length > 0 &&
                            $t('Destination address')
                          "
                          :placeholder="$t('Destination address')"
                          country="ve"
                          outlined
                          dense
                          clearable
                          :disabled="!stateOriginAddressMap"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          enable-geolocation
                          v-model="createTripForm.destinationAddress"
                          @placechanged="setPlaceDestination"
                          types=""
                        >
                          <template v-slot:prepend-inner>
                            <div>
                              <v-img
                                :src="
                                  require('../../../assets/IconDestinationMap.png')
                                "
                              ></v-img>
                            </div>
                          </template>
                        </vuetify-google-autocomplete>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- Usuarios corporativos -->
                  <v-row
                    dense
                    class="margin"
                    v-if="createTripForm.tripType !== 'Quick quote'"
                  >
                    <v-col :cols="12">
                      <ValidationProvider
                        :name="$t('Parcel responsible user')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-autocomplete
                          v-if="createTripForm.userType === 'Corporate'"
                          v-model="createTripForm.userID"
                          :label="$t('Parcel responsible user')"
                          :items="createTripForm.corporateUsers"
                          outlined
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          item-value="_id"
                          :menu-props="{ contentClass: 'list-style' }"
                          :filter="handleFilterAutocompleteCorporateUsers"
                          dense
                          @click="createTripForm.userID = null"
                        >
                          <template v-slot:selection="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <!-- color="primary"
                              small
                              outlined -->
                                <v-avatar
                                  color="success"
                                  size="25"
                                  class="mr-1"
                                >
                                  <span
                                    class="d-flex align-center justify-center"
                                  >
                                    <v-img
                                      :src="`${bucketUrl}${item.picture}`"
                                      :alt="item.initials"
                                      width="25"
                                      v-if="item.picture"
                                    >
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height ma-0"
                                          align="center"
                                          justify="center"
                                        >
                                          <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                          ></v-progress-circular>
                                        </v-row> </template
                                    ></v-img>
                                    <span
                                      v-else
                                      class="color-blue-text text-caption"
                                    >
                                      {{ item.initials }}
                                    </span>
                                  </span>
                                </v-avatar>
                                <span v-bind="attrs" v-on="on">
                                  <span class="">
                                    {{ item.fullName }}
                                  </span>
                                </span>
                              </template>
                              <span class="d-flex flex-column">
                                <span class="d-flex flex-row align-center">
                                  <span class="ml-1">
                                    {{ item.phone }}
                                  </span>
                                  <v-icon
                                    x-small
                                    class="ml-1"
                                    style="color: #ffffff !important"
                                  >
                                    {{ icons.mdiPhoneOutline }}
                                  </v-icon>
                                  <span class="ml-1">|</span>
                                  <span class="ml-1">
                                    {{ item.email }}
                                  </span>
                                  <v-icon
                                    x-small
                                    class="ml-1"
                                    style="color: #ffffff !important"
                                  >
                                    {{ icons.mdiEmailOutline }}
                                  </v-icon>
                                </span>
                                <span class="mt-1">
                                  <span class="ml-1">
                                    <span class="font-weight-bold">
                                      {{ $t("user_wallet") }}:
                                    </span>
                                    {{ item.wallet | currency }}
                                  </span>
                                  <span class="ml-1">|</span>
                                  <span class="ml-1">
                                    <span class="font-weight-bold">
                                      {{ $t("Overdraft") }}:
                                    </span>
                                    {{ item.overdraft ? $t("Yes") : "No" }}
                                  </span>
                                </span>
                              </span>
                            </v-tooltip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-avatar color="#3BD4AE" size="45">
                              <span v-if="item.picture">
                                <v-img
                                  :src="`${bucketUrl}${item.picture}`"
                                  :alt="item.initials"
                                  width="45"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row> </template
                                ></v-img>
                              </span>
                              <span v-else>
                                {{ item.initials }}
                              </span>
                            </v-list-item-avatar>
                            <br />
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.fullName }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <span class="d-flex flex-column">
                                  <span>
                                    <span>
                                      {{ item.phone }}
                                    </span>
                                    <v-icon x-small class="ml-1">
                                      {{ icons.mdiPhoneOutline }}
                                    </v-icon>
                                    <span class="ml-1">|</span>
                                    <span class="ml-1">
                                      {{ item.email }}
                                    </span>
                                    <v-icon x-small class="ml-1">
                                      {{ icons.mdiEmailOutline }}
                                    </v-icon>
                                  </span>
                                  <span class="mt-1">
                                    <span class="ml-1">
                                      <span class="font-weight-bold">
                                        {{ $t("user_wallet") }}:
                                      </span>
                                      {{ item.wallet | currency }}
                                    </span>
                                    <span class="ml-1">|</span>
                                    <span class="ml-1">
                                      <span class="font-weight-bold">
                                        {{ $t("Overdraft") }}:
                                      </span>
                                      {{ item.overdraft ? $t("Yes") : "No" }}
                                    </span>
                                  </span>
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                          <template v-slot:append-outer>
                            <div>
                              <v-tooltip top :max-width="200">
                                <template #activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on">
                                    {{ icons.mdiInformationOutline }}
                                  </v-icon>
                                </template>
                                <span>
                                  {{ $t("This input is autocomplete") }}
                                  <br />
                                  {{ $t("you may search corporate users by:") }}
                                  <ul class="mt-2">
                                    <li>
                                      {{ $t("fullName") }}
                                    </li>
                                    <li>
                                      {{ $t("Email") }}
                                    </li>
                                    <li>
                                      {{ $t("Phone") }}
                                    </li>
                                  </ul>
                                </span>
                              </v-tooltip>
                            </div>
                          </template>
                        </v-autocomplete>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- Checkbox de saldo corporativo -->
                  <v-row
                    style="margin-top: -35px !important"
                    dense
                    class="margin"
                    v-if="
                      createTripForm.userType === 'Corporate' &&
                      createTripForm.userID &&
                      createTripForm.tripType !== 'Quick quote'
                    "
                  >
                    <v-col
                      cols="12"
                      class="d-flex flex-row justify-start ml-2 align-center"
                    >
                      <v-switch v-model="createTripForm.isCorporateWallet">
                        <template v-slot:label>
                          <span class="text-caption">
                            <span v-if="!createTripForm.isCorporateWallet">
                              Usar <u>saldo del usuario</u> para el viaje
                            </span>
                            <span v-else>
                              Usar <u>balance corporativo</u> para el viaje
                            </span>
                            <span>
                              <v-tooltip top :max-width="300">
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    class="color-gray-text"
                                    :style="handleColorClose"
                                  >
                                    {{ icons.mdiInformationOutline }}
                                  </v-icon>
                                </template>
                                <span v-if="createTripForm.isCorporateWallet">
                                  Al momento de crear el viaje para el usuario
                                  corporativo
                                  <b>{{
                                    computedCurrentCorporateUser.fullName
                                  }}</b>
                                  , el costo del mismo será debitado del
                                  <u>balance corporativo</u> ({{
                                    wallet | currency
                                  }}), no del <u>saldo</u> ({{
                                    computedCurrentCorporateUser.wallet
                                      | currency
                                  }})
                                  <span
                                    v-if="
                                      computedCurrentCorporateUser.overdraft
                                    "
                                  >
                                    ni <u>sobregiro</u>
                                  </span>
                                  de
                                  <b>{{
                                    computedCurrentCorporateUser.fullName
                                  }}</b>
                                </span>
                                <span v-else>
                                  Al momento de crear el viaje para el usuario
                                  corporativo
                                  <b>{{
                                    computedCurrentCorporateUser.fullName
                                  }}</b
                                  >, el costo del mismo será debitado de su
                                  <u>saldo</u> ({{
                                    computedCurrentCorporateUser.wallet
                                      | currency
                                  }})
                                  <span
                                    v-if="
                                      computedCurrentCorporateUser.overdraft
                                    "
                                  >
                                    o de su <u>sobregiro</u>
                                  </span>
                                </span>
                              </v-tooltip>
                            </span>
                          </span>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                  <!-- responsable de recibir -->
                  <v-row class="margin" dense v-if="false">
                    <v-col cols="12" style="margin-top: -10px">
                      <small>{{ $t("parcel_receiver") }}</small>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ml-2" small v-bind="attrs" v-on="on">{{
                            icons.mdiInformationOutline
                          }}</v-icon>
                        </template>
                        <span>{{ $t("parcel_receives_tooltip") }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="6">
                      <ValidationProvider
                        :name="$t('Nombre')"
                        rules="required|min:2|alpha_spaces"
                        v-slot="{ errors }"
                        v-if="createTripForm.tripType !== 'Quick quote'"
                        slim
                      >
                        <!-- autocomplete="new-password" para evitar sugerencias de chrome -->
                        <v-text-field
                          v-if="createTripForm.tripType !== 'Quick quote'"
                          v-model="createTripForm.userFirstName"
                          :label="$t('Name')"
                          outlined
                          dense
                          autocomplete="new-password"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols="6"
                      v-if="createTripForm.tripType !== 'Quick quote'"
                    >
                      <ValidationProvider
                        :name="$t('Last name')"
                        rules="required|min:2|alpha_spaces"
                        v-slot="{ errors }"
                        v-if="createTripForm.tripType !== 'Quick quote'"
                        slim
                      >
                        <v-text-field
                          v-model="createTripForm.userLastName"
                          v-if="createTripForm.tripType !== 'Quick quote'"
                          :label="$t('Last name')"
                          outlined
                          dense
                          autocomplete="new-password"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="5">
                      <ValidationProvider
                        :name="$t('Operator')"
                        rules="required"
                        v-slot="{ errors }"
                        v-if="createTripForm.tripType !== 'Quick quote'"
                        slim
                      >
                        <v-select
                          :items="createTripForm.phonePrefixList"
                          :label="$t('Operator')"
                          outlined
                          dense
                          v-model="createTripForm.phonePrefix"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          v-if="createTripForm.tripType !== 'Quick quote'"
                        >
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="7">
                      <!-- <code>{{createTripForm.phone}}</code> -->
                      <ValidationProvider
                        :name="$t('Telephone')"
                        rules="required|min:7|max:7"
                        v-slot="{ errors }"
                        v-if="createTripForm.tripType !== 'Quick quote'"
                        slim
                      >
                        <PhoneInput
                          :label="$t('Telephone')"
                          v-model="createTripForm.phone"
                          dense
                          outlined
                          :long="false"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          v-if="createTripForm.tripType !== 'Quick quote'"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- tipo de servicio -->
                  <v-row dense class="margin">
                    <v-col cols="12">
                      <!-- <code>{{ createTripForm.serviceType }}</code>
                  <br />
                  <code>{{ Boolean(stateServiceTypeOrigin) }}</code> -->
                      <ValidationProvider
                        :name="$t('Service type')"
                        rules="required"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-select
                          :items="createTripForm.serviceTypeOptions"
                          :label="$t('Service type')"
                          outlined
                          dense
                          v-model="createTripForm.serviceType"
                          :error-messages="
                            errors[0] && [$t('Obligatory field')]
                          "
                          :disabled="!stateOriginAddressMap"
                          item-text="type"
                          item-value="_id"
                          :menu-props="{ contentClass: 'list-style' }"
                          class="d-flex flex-row align-center"
                        >
                          <template
                            v-slot:append-outer
                            v-if="createTripForm.serviceType"
                          >
                            <v-tooltip top :max-width="300">
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  icon
                                  @click="getServiceTypeDrivers()"
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mb-7"
                                >
                                  <v-icon>
                                    {{ icons.mdiRefresh }}
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>
                                <!-- <ul> -->
                                <!-- <li> -->
                                {{
                                  $t(
                                    "By clicking you can refresh the nearby providers in the map"
                                  )
                                }}
                                <!-- </li> -->
                                <!-- </ul> -->
                              </span>
                            </v-tooltip>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div
                              class="
                                d-flex
                                flex-row
                                justify-space-between
                                font-weight-bold
                              "
                            >
                              <div class="d-flex flex-row align-center">
                                <v-img
                                  :src="`${bucketUrl}${item.image}`"
                                  max-width="45"
                                >
                                  <!-- max-height="20" -->
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row> </template
                                ></v-img>
                                <h4 class="fontUrbanist ml-2">
                                  {{ item.type }}
                                </h4>
                                <span class="d-flex align-center ml-2">
                                  <v-tooltip top content-class="test">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on" small>
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </template>
                                    <template #default>
                                      <v-card
                                        class="pa-4 fontUrbanist elevation-24"
                                        max-width="600"
                                      >
                                        <v-row
                                          dense
                                          class="d-flex flex-row align-bottom"
                                        >
                                          <v-col cols="4">
                                            <v-img
                                              :src="`${bucketUrl}${item.image}`"
                                              max-width="400"
                                            >
                                              <!-- max-height="20" -->
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height ma-0"
                                                  align="center"
                                                  justify="center"
                                                >
                                                  <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                  ></v-progress-circular>
                                                </v-row> </template
                                            ></v-img>
                                          </v-col>
                                          <v-col
                                            cols="8"
                                            style="
                                              border-bottom: solid 3px #3bd4ae;
                                            "
                                            class="
                                              d-flex
                                              flex-column
                                              justify-center
                                              pt-4
                                            "
                                          >
                                            <v-row dense>
                                              <v-col cols="12">
                                                <h1 class="fontUrbanist">
                                                  {{ item.type }}
                                                </h1>
                                              </v-col>
                                            </v-row>
                                            <v-row dense class="pt-4">
                                              <v-col cols="6">
                                                <h3>
                                                  {{
                                                    $t("parcel_package_size")
                                                  }}
                                                </h3>
                                                <p class="text-md">
                                                  {{
                                                    item.type_details
                                                      .max_package_width
                                                  }}
                                                  x
                                                  {{
                                                    item.type_details
                                                      .max_package_height
                                                  }}
                                                  x
                                                  {{
                                                    item.type_details
                                                      .max_package_depth
                                                  }}
                                                  cm
                                                </p>
                                              </v-col>
                                              <v-col cols="6">
                                                <h3>
                                                  {{
                                                    $t("parcel_package_mass")
                                                  }}
                                                </h3>
                                                <p class="text-md">
                                                  {{
                                                    item.type_details
                                                      .max_package_weight
                                                  }}
                                                  Kg
                                                </p>
                                              </v-col>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                        <v-row dense class="mt-4">
                                          <v-col
                                            cols="12"
                                            class="
                                              d-flex
                                              flex-column
                                              align-start
                                            "
                                          >
                                            <h2 class="">
                                              {{
                                                $t("parcel_package_examples")
                                              }}
                                            </h2>
                                            <p class="text-md text-justify">
                                              {{
                                                $vuetify.lang.current === "es"
                                                  ? item.type_details
                                                      .shipment_description_es
                                                  : item.type_details
                                                      .shipment_description_en
                                              }}
                                            </p>
                                          </v-col>
                                        </v-row>
                                      </v-card>
                                    </template>
                                  </v-tooltip>
                                </span>
                              </div>
                            </div>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-img
                              :src="`${bucketUrl}${item.image}`"
                              max-width="45"
                            >
                              <!-- max-height="20" -->
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row> </template
                            ></v-img>
                            <v-list-item-content>
                              <v-list-item-title class="fontUrbanist ml-1">
                                <h4>
                                  {{ item.type }}
                                </h4>
                              </v-list-item-title>
                              <v-list-item-subtitle class="ml-1" v-if="false">
                                <span class="d-flex align-center">
                                  <v-icon x-small>
                                    {{ icons.mdiAccount }}
                                  </v-icon>
                                  <span class="ml-1">
                                    {{ item.capacity }}
                                  </span>
                                  <span class="ml-1">|</span>
                                  <v-icon x-small class="ml-1">
                                    {{ icons.mdiBagSuitcase }}
                                  </v-icon>
                                  <span class="ml-1">
                                    {{ item.baggage }}
                                  </span>
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <!-- detalles del paquete -->
                  <v-row
                    dense
                    class="margin"
                    :class="!$vuetify.breakpoint.mdAndUp && 'mb-4'"
                    justify="center"
                    v-if="false"
                  >
                    <ValidationProvider
                      :name="$t('parcel_textarea')"
                      rules="required|min:2"
                      v-slot="{ errors }"
                      v-if="createTripForm.tripType !== 'Quick quote'"
                      slim
                    >
                      <v-textarea
                        dense
                        v-model="paquete.textarea"
                        height="70"
                        outlined
                        clearable
                        :rules="[
                          (v) =>
                            (v || '').length <= 150 ||
                            `${$t('parcel_textarea_error')}`,
                        ]"
                        :label="$t('parcel_textarea')"
                        placeholder=" Ej. Objeto Frágil / Caja de chocolates/ Agenda."
                        :error-messages="errors[0] && [$t('Obligatory field')]"
                      ></v-textarea>
                    </ValidationProvider>
                  </v-row>
                </template>
              </ValidationObserver>

              <!-- paso 2 -->

              <ValidationObserver>
                <template v-if="step === 2">
                  <!-- cotizacion rapida -->
                  <template v-if="false">
                    <v-row
                      dense
                      v-for="(value, key) in demoQuote"
                      :key="value.index"
                    >
                      <v-col
                        cols="12"
                        v-if="key !== 'TOTAL'"
                        :class="
                          $vuetify.breakpoint.lgAndUp
                            ? 'text-body-2'
                            : 'text-caption'
                        "
                      >
                        <span
                          v-if="key === 'Dirección de origen'"
                          class="font-weight-bold d-flex flex-row"
                          :class="!$vuetify.theme.isDark && 'color-blue-text'"
                        >
                          <v-img
                            :src="require('../../../assets/IconOriginMap.png')"
                            style="max-width: 20px"
                          ></v-img>
                          {{ $t(key) }}
                          :
                        </span>
                        <span
                          v-if="key === 'Dirección de destino'"
                          class="font-weight-bold d-flex flex-row"
                          :class="!$vuetify.theme.isDark && 'color-blue-text'"
                        >
                          <v-img
                            :src="
                              require('../../../assets/IconDestinationMap.png')
                            "
                            style="max-width: 20px"
                          ></v-img>
                          {{ $t(key) }}
                          :
                        </span>
                        <span
                          v-if="key === 'Tipo de servicio'"
                          class="font-weight-bold d-flex flex-row align-center"
                          :class="!$vuetify.theme.isDark && 'color-blue-text'"
                        >
                          {{ $t(key) }}:
                          <span
                            class="
                              d-flex
                              flex-row
                              justify-center
                              align-center
                              ml-2
                            "
                            v-if="value"
                          >
                            <span
                              class="
                                d-flex
                                flex-row
                                justify-center
                                align-center
                              "
                            >
                              <v-img
                                :src="`${bucketUrl}${value.type_image_url}`"
                                max-width="45"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row> </template
                              ></v-img>
                              <span
                                class="fontUrbanist font-weight-bold ml-2"
                                >{{ value.typename }}</span
                              >
                            </span>
                          </span>
                        </span>
                        <span
                          v-if="
                            key !== 'Dirección de origen' &&
                            key !== 'Dirección de destino' &&
                            key !== 'Tipo de servicio'
                          "
                          class="font-weight-bold"
                          :class="!$vuetify.theme.isDark && 'color-blue-text'"
                          >{{ $t(key) }}:</span
                        >
                        <span
                          :class="!$vuetify.theme.isDark && 'color-blue-text'"
                          v-if="key !== 'Tipo de servicio'"
                        >
                          {{ $t(value) }}</span
                        >
                        <span
                          :class="!$vuetify.theme.isDark && 'color-blue-text'"
                          v-if="key === 'Usuario responsable'"
                        >
                          {{ $t(key.value) }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="mb-1"
                                v-bind="attrs"
                                v-on="on"
                                >{{ icons.mdiInformationOutline }}</v-icon
                              >
                            </template>
                            <span>{{ $t("person_is_contact") }}</span>
                          </v-tooltip>
                        </span>
                        <span
                          :class="!$vuetify.theme.isDark && 'color-blue-text'"
                          v-if="key === 'Quien recibe envío'"
                        >
                          {{ $t(key.value) }}
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="mb-1"
                                v-bind="attrs"
                                v-on="on"
                                >{{ icons.mdiInformationOutline }}</v-icon
                              >
                            </template>
                            <span>{{ $t("person_is_contact") }}</span>
                          </v-tooltip>
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Cotización formal -->
                  <template v-if="createTripForm.tripType === 'Quick quote'">
                    <v-row dense>
                      <v-col cols="12">
                        <p class="text--primary shipment-summary">
                          {{ $t("parcel_shipment_summary") }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row dense class="border-bottom-gray">
                      <v-col
                        cols="12"
                        class="d-flex flex-column justify-center align-center"
                      >
                        <span
                          class="text--primary fontUrbanist font-weight-bold"
                          >{{ demoQuote["Tipo de servicio"].typename }}</span
                        >
                        <v-img
                          :src="`${bucketUrl}${demoQuote['Tipo de servicio'].type_image_url}`"
                          max-width="70"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row> </template
                        ></v-img>
                      </v-col>
                    </v-row>
                    <!-- origen quote -->
                    <v-row
                      dense
                      class="
                        d-flex
                        flex-row
                        align-center
                        justify-center
                        text-sm
                        border-bottom-gray
                        mt-2
                      "
                      ><v-col
                        cols="1"
                        class="d-flex flex-row align-center justify-center"
                      >
                        <v-img
                          :src="require('../../../assets/IconOriginMapMap.png')"
                          contain
                          max-width="40"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col
                        cols="11"
                        class="d-flex flex-column align-start justify-center"
                      >
                        <!-- origen -->
                        <span class="text-justify">
                          <span class="font-weight-bold success--text"
                            >{{ $t("parcel_quote_origin") }}:</span
                          >
                          <v-tooltip top max-width="300" :disabled="!false">
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                class="ml-1 text-justify"
                                v-bind="attrs"
                                v-on="on"
                                >{{
                                  truncator(
                                    demoQuote["Dirección de origen"],
                                    300
                                  )
                                }}</span
                              >
                            </template>
                            <span>{{ demoQuote["Dirección de origen"] }}</span>
                          </v-tooltip>
                        </span>
                      </v-col>
                    </v-row>
                    <!-- destination quote -->
                    <v-row
                      dense
                      class="
                        d-flex
                        flex-row
                        align-center
                        justify-center
                        text-sm
                        border-bottom-gray
                        mt-2
                      "
                      ><v-col
                        cols="1"
                        class="d-flex flex-row align-center justify-center"
                      >
                        <v-img
                          :src="
                            require('../../../assets/IconDestinationMapMap.png')
                          "
                          contain
                          max-width="40"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col
                        cols="11"
                        class="d-flex flex-column align-start justify-center"
                      >
                        <!-- origen -->
                        <span class="text-justify">
                          <span class="font-weight-bold error--text"
                            >{{ $t("parcel_quote_destination") }}:</span
                          >
                          <v-tooltip top max-width="300" :disabled="!false">
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                class="ml-1 text-justify"
                                v-bind="attrs"
                                v-on="on"
                                >{{
                                  truncator(
                                    demoQuote["Dirección de destino"],
                                    300
                                  )
                                }}</span
                              >
                            </template>
                            <span>{{ demoQuote["Dirección de destino"] }}</span>
                          </v-tooltip>
                        </span>
                      </v-col>
                    </v-row>

                    <!-- total a pagar y surge -->
                    <v-row dense class="mt-2 text-sm mb-6">
                      <v-col cols="12">
                        <span class="font-weight-bold primary--text">
                          {{ $t("Parcel Type") }}:
                        </span>
                        <span> {{ $t(createTripForm.tripType) }} </span>
                      </v-col>
                      <v-col cols="12">
                        <span class="font-weight-bold primary--text">
                          {{ $t("Date and Time") }}:
                        </span>
                        <span> {{ demoQuote["Fecha y hora"] }}</span>
                      </v-col>
                      <v-col cols="12" class="mb-2">
                        <span class="font-weight-bold primary--text">
                          {{ $t("Sobredemanda") }}:
                        </span>
                        <span> {{ demoQuote["Sobredemanda"] }} </span>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <span>
                          <v-icon color="primary">
                            {{ icons.mdiMapMarkerDistance }}
                          </v-icon>
                        </span>
                        <span class="ml-1"> {{ demoQuote["DISTANCIA"] }} </span>
                      </v-col>
                      <v-col
                        cols="6"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <span>
                          <v-icon color="primary">
                            {{ icons.mdiClockOutline }}
                          </v-icon>
                        </span>
                        <span class="ml-1"> {{ demoQuote["DURACIÓN"] }} </span>
                      </v-col>
                    </v-row>
                  </template>
                  <!-- datos de envios -->
                  <template v-else>
                    <v-row dense>
                      <v-col
                        cols="5"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <v-btn
                          color="success"
                          :outlined="!(createTripForm.iSend === '1')"
                          small
                          block
                          max-width="100"
                          class="flex-color"
                          @click="createTripForm.iSend = '1'"
                        >
                          <span
                            class="
                              d-flex
                              flex-column
                              align-center
                              justify-center
                            "
                            :class="!$vuetify.theme.isDark && 'color-blue-text'"
                          >
                            {{ $t("parcel_i_send") }}
                            <span class="" style="font-size: 8.5px !important">
                              {{
                                computedSenderButton
                                  ? computedSenderButton
                                  : $t("parcel_not_defined_remittent")
                              }}
                            </span>
                          </span>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="1"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <v-img
                          :src="require('../../../assets/IconOriginMapMap.png')"
                          style="width: 25px"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col
                        cols="5"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <v-btn
                          color="error"
                          :outlined="!(createTripForm.iSend === '2')"
                          small
                          block
                          max-width="100"
                          class="flex-color"
                          @click="createTripForm.iSend = '2'"
                        >
                          <span
                            class="
                              d-flex
                              flex-column
                              align-center
                              justify-center
                            "
                            :class="!$vuetify.theme.isDark && 'color-blue-text'"
                          >
                            {{ $t("parcel_i_receive") }}
                            <span class="" style="font-size: 8.5px !important">
                              {{
                                computedReceiverButton
                                  ? computedReceiverButton
                                  : $t("parcel_not_defined_remittent")
                              }}
                            </span>
                          </span>
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="1"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <v-img
                          :src="
                            require('../../../assets/IconDestinationMapMap.png')
                          "
                          style="width: 25px"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex flex-row justify-center align-center"
                      >
                        <v-btn icon @click="handleAlternate()">
                          <v-icon
                            medium
                            color="primary"
                            class="toggleUpDown"
                            :class="{ rotate: createTripForm.iSend === '2' }"
                          >
                            {{ icons.mdiSwapHorizontal }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row dense class="mt-2">
                      <v-col
                        cols="12"
                        v-if="createTripForm.iSend === '1'"
                        class="toggleUpOpacity"
                      >
                        <ValidationProvider
                          :name="$t('parcel_textarea_1_send')"
                          rules="max:200"
                          v-slot="{ errors }"
                          v-if="createTripForm.tripType !== 'Quick quote'"
                          slim
                        >
                          <v-textarea
                            dense
                            v-model="paquete.textarea1"
                            height="70"
                            outlined
                            :label="$t('parcel_textarea_1_send')"
                            :error-messages="errors[0] && errors[0]"
                          >
                            <template #append-outer>
                              <span class="" style="height: 100%">
                                <v-img
                                  :src="
                                    require('../../../assets/IconOriginMapMap.png')
                                  "
                                  style="width: 50px"
                                  contain
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </span>
                            </template>
                          </v-textarea>
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="createTripForm.iSend === '2'"
                        class="toggleUpOpacity"
                      >
                        <ValidationProvider
                          :name="$t('parcel_textarea_2_send')"
                          rules="max:200"
                          v-slot="{ errors }"
                          v-if="createTripForm.tripType !== 'Quick quote'"
                          slim
                        >
                          <v-textarea
                            dense
                            v-model="paquete.textarea2"
                            height="70"
                            outlined
                            :label="$t('parcel_textarea_2_send')"
                            :error-messages="errors[0] && errors[0]"
                          >
                            <template #append-outer>
                              <span class="" style="height: 100%">
                                <v-img
                                  :src="
                                    require('../../../assets/IconDestinationMapMap.png')
                                  "
                                  style="width: 50px"
                                  contain
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </span>
                            </template>
                          </v-textarea>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row
                      dense
                      style="margin-top: -0.5rem"
                      class="font-weight-bold"
                    >
                      <v-col>
                        <p>
                          {{
                            createTripForm.iSend === "1"
                              ? $t("parcel_data_person_send")
                              : $t("parcel_data_person_receive")
                          }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row dense class="margin">
                      <v-col cols="12">
                        <ValidationProvider
                          :name="$t('parcel_user_full_name')"
                          rules="required|min:2|alpha_spaces|max:35"
                          v-slot="{ errors }"
                          v-if="createTripForm.tripType !== 'Quick quote'"
                          slim
                        >
                          <!-- autocomplete="new-password" para evitar sugerencias de chrome -->
                          <v-text-field
                            v-if="createTripForm.tripType !== 'Quick quote'"
                            v-model="createTripForm.userFullName"
                            :label="$t('parcel_user_full_name')"
                            outlined
                            dense
                            autocomplete="new-password"
                            :error-messages="errors[0] && errors[0]"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense class="margin">
                      <v-col cols="5">
                        <ValidationProvider
                          :name="$t('Operator')"
                          rules="required"
                          v-slot="{ errors }"
                          v-if="createTripForm.tripType !== 'Quick quote'"
                          slim
                        >
                          <v-select
                            :items="createTripForm.phonePrefixList"
                            :label="$t('Operator')"
                            outlined
                            dense
                            v-model="createTripForm.phonePrefix"
                            :error-messages="errors[0]"
                            v-if="createTripForm.tripType !== 'Quick quote'"
                          >
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="7">
                        <!-- <code>{{createTripForm.phone}}</code> -->
                        <ValidationProvider
                          :name="$t('Telephone')"
                          rules="required|min:7|max:7"
                          v-slot="{ errors }"
                          v-if="createTripForm.tripType !== 'Quick quote'"
                          slim
                        >
                          <PhoneInput
                            :label="$t('Telephone')"
                            v-model="createTripForm.phone"
                            dense
                            outlined
                            :long="false"
                            :error-messages="errors[0]"
                            v-if="createTripForm.tripType !== 'Quick quote'"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row dense class="mt-6">
                      <v-col
                        cols="12"
                        v-if="createTripForm.iSend === '1'"
                        class="toggleUpOpacity"
                      >
                        <ValidationProvider
                          :name="$t('parcel_textarea_2_send')"
                          rules="max:200"
                          v-slot="{ errors }"
                          v-if="createTripForm.tripType !== 'Quick quote'"
                          slim
                        >
                          <v-textarea
                            dense
                            v-model="paquete.textarea2"
                            height="70"
                            outlined
                            :label="$t('parcel_textarea_2_send')"
                            :error-messages="errors[0] && errors[0]"
                          >
                            <template #append-outer>
                              <span class="" style="height: 100%">
                                <v-img
                                  :src="
                                    require('../../../assets/IconDestinationMapMap.png')
                                  "
                                  style="width: 50px"
                                  contain
                                ></v-img>
                              </span>
                            </template>
                          </v-textarea>
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="createTripForm.iSend === '2'"
                        class="toggleUpOpacity"
                      >
                        <ValidationProvider
                          :name="$t('parcel_textarea_1_send')"
                          rules="max:200"
                          v-slot="{ errors }"
                          v-if="createTripForm.tripType !== 'Quick quote'"
                          slim
                        >
                          <v-textarea
                            dense
                            v-model="paquete.textarea1"
                            height="70"
                            outlined
                            :label="$t('parcel_textarea_1_send')"
                            :error-messages="errors[0] && errors[0]"
                          >
                            <template #append-outer>
                              <span class="" style="height: 100%">
                                <v-img
                                  :src="
                                    require('../../../assets/IconOriginMapMap.png')
                                  "
                                  style="width: 50px"
                                  contain
                                ></v-img>
                              </span>
                            </template>
                          </v-textarea>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </template>
                </template>
              </ValidationObserver>

              <!-- paso 3 -->

              <ValidationObserver>
                <template v-if="step === 3">
                  <v-row dense
                    ><v-col cols="12">
                      <p class="font-weight-bold">
                        {{ $t("parcel_package")
                        }}<span
                          v-show="!paquete.imageUrl"
                          class="color-red-text"
                          >*</span
                        >
                      </p>
                    </v-col></v-row
                  >
                  <v-row dense>
                    <v-col cols="12" class="d-flex justify-center mb-4">
                      <v-badge dot color="error" v-show="!paquete.imageUrl">
                        <v-icon
                          size="100"
                          color="primary"
                          @click="handleClickUpload"
                          v-show="!paquete.imageUrl"
                        >
                          {{ icons.mdiPackageVariantClosed }}
                        </v-icon>
                      </v-badge>
                      <v-dialog
                        v-if="paquete.image"
                        v-model="dialogStep3"
                        fullscreen
                        transition="dialog-bottom-transition"
                        scrollable
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-fade-transition mode="out-in">
                            <v-img
                              :src="paquete.imageUrl && paquete.imageUrl"
                              width="100"
                              height="100"
                              v-bind="attrs"
                              v-on="on"
                              class="hover-image rounded-lg"
                              contain
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-fade-transition>
                        </template>
                        <v-card class="testcard" style="overflow-y: auto">
                          <v-row dense>
                            <v-col cols="12" class="">
                              <v-toolbar
                                color="primary"
                                class="d-flex justify-space-between"
                                tile
                              >
                                <span
                                  class="d-flex flex-row justify-space-between"
                                  style="width: 98vw"
                                >
                                  <v-toolbar-title class="color-white-text">{{
                                    $t("parcel_package_image")
                                  }}</v-toolbar-title>
                                  <v-btn icon dark @click="dialogStep3 = false">
                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                  </v-btn>
                                </span>
                              </v-toolbar>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col
                              cols="12"
                              class="d-flex align-start justify-center pa-12"
                            >
                              <v-img
                                :src="paquete.imageUrl && paquete.imageUrl"
                                class="modal-image"
                                contain
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="primary"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col
                      cols="12"
                      class="d-flex flex-row justify-center align-center"
                    >
                      <v-btn color="success" @click="handleClickUpload">
                        {{ $t("parcel_upload_image") }}
                      </v-btn>
                      <v-file-input
                        v-model="paquete.image"
                        ref="file"
                        class="d-none"
                        @change="previewImage"
                        accept="image/png, image/jpg, image/jpeg"
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-md-6">
                    <v-col cols="12">
                      <ValidationProvider
                        :name="$t('parcel_textarea_package')"
                        rules="required|min:2|max:200"
                        v-slot="{ errors }"
                        slim
                      >
                        <v-textarea
                          dense
                          v-model="paquete.textareaPackage"
                          height="70"
                          outlined
                          :label="$t('parcel_textarea_package')"
                          :error-messages="errors[0] && errors[0]"
                        ></v-textarea>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-1">
                    <v-col cols="12">
                      <v-img
                        :src="
                          $vuetify.lang.current === 'es'
                            ? require('../../../assets/seguroparcelES.png')
                            : require('../../../assets/seguroparcelEN.png')
                        "
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </template>
              </ValidationObserver>

              <!-- checkbox y articulos prohibidos -->
              <template v-if="step === 4">
                <v-row dense>
                  <v-col cols="12">
                    <p
                      class="fontUrbanist"
                      :class="
                        !$vuetify.theme.isDark
                          ? 'color-blue-text'
                          : 'color-white-text'
                      "
                      style="font-size: 1.2rem"
                    >
                      <span
                        class="font-weight-bold error--text"
                        style="font-size: 1.5rem"
                        >{{ $t("parcel_prohibited_items") }}</span
                      ><br />
                      {{ $t("parcel_prohibited_items_shipments") }}
                    </p>
                  </v-col>
                </v-row>
                <v-row dense class="d-flex flex-row justify-center align-start">
                  <v-col
                    cols="3"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <v-img
                      src="../../../assets/prohibited/bullet.png"
                      width="58"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p class="text-center prohibited-text">
                      {{ $t("parcel_prohibited_fireamrs") }}
                    </p>
                  </v-col>
                  <v-col
                    cols="3"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <v-img
                      src="../../../assets/prohibited/skull.png"
                      width="58"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p class="text-center prohibited-text">
                      {{ $t("parcel_prohibited_substances") }}
                    </p>
                  </v-col>
                  <v-col
                    cols="3"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <v-img src="../../../assets/prohibited/cash.png" width="58">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p class="text-center prohibited-text">
                      {{ $t("parcel_prohibited_cash") }}
                    </p>
                  </v-col>
                  <v-col
                    cols="3"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <v-img
                      src="../../../assets/prohibited/nuclearWinter.png"
                      width="58"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p class="text-center prohibited-text">
                      {{ $t("parcel_prohibited_waste") }}
                    </p>
                  </v-col>
                </v-row>
                <v-row dense class="d-flex flex-row justify-center align-start">
                  <v-col
                    cols="3"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <v-img
                      src="../../../assets/prohibited/notBTC.png"
                      width="58"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p class="text-center prohibited-text">
                      {{ $t("parcel_prohibited_lingots") }}
                    </p>
                  </v-col>
                  <v-col
                    cols="3"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <v-img
                      src="../../../assets/prohibited/firework.png"
                      width="58"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p class="text-center prohibited-text">
                      {{ $t("parcel_prohibited_fireworks") }}
                    </p>
                  </v-col>
                  <v-col
                    cols="3"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <v-img
                      src="../../../assets/prohibited/insecticide.png"
                      width="58"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p class="text-center prohibited-text">
                      {{ $t("parcel_prohibited_insecticides") }}
                    </p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-checkbox v-model="paquete.checkbox">
                      <template v-slot:label>
                        <div
                          :class="
                            !$vuetify.theme.isDark
                              ? 'color-blue-text'
                              : 'color-white-text'
                          "
                        >
                          <span v-if="$vuetify.lang.current === 'es'">
                            He leído y estoy de acuerdo con las
                            <a href="/parcel-guide" target="_blank" @click.stop>
                              Políticas</a
                            >
                            de envíos de
                            <span class="font-weight-bold fontUrbanist text-lg"
                              >Ridery</span
                            >
                          </span>
                          <span v-else>
                            I have read and I agree to the
                            <a href="/parcel-guide" target="_blank" @click.stop>
                              Policies
                            </a>
                            related to parcels from
                            <span class="font-weight-bold fontUrbanist text-lg"
                              >Ridery</span
                            >
                          </span>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </template>

              <!-- Cotización formal -->
              <template v-if="step === 5">
                <v-row dense>
                  <v-col cols="12">
                    <p class="text--primary shipment-summary">
                      {{ $t("parcel_shipment_summary") }}
                    </p>
                  </v-col>
                </v-row>
                <v-row dense class="border-bottom-gray">
                  <v-col
                    cols="12"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <span class="text--primary fontUrbanist font-weight-bold">{{
                      demoQuote["Tipo de servicio"].typename
                    }}</span>
                    <v-img
                      :src="`${bucketUrl}${demoQuote['Tipo de servicio'].type_image_url}`"
                      max-width="70"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row> </template
                    ></v-img>
                  </v-col>
                </v-row>
                <!-- origen quote -->
                <v-row
                  dense
                  class="
                    d-flex
                    flex-row
                    align-center
                    justify-center
                    text-sm
                    border-bottom-gray
                    mt-2
                  "
                  ><v-col
                    cols="1"
                    class="d-flex flex-row align-center justify-center"
                  >
                    <v-img
                      :src="require('../../../assets/IconOriginMapMap.png')"
                      contain
                      max-width="40"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col
                    cols="11"
                    class="d-flex flex-column align-start justify-center"
                  >
                    <!-- origen -->
                    <span class="text-justify">
                      <span class="font-weight-bold success--text"
                        >{{ $t("parcel_quote_origin") }}:</span
                      >
                      <v-tooltip top max-width="300" :disabled="!false">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="ml-1 text-justify"
                            v-bind="attrs"
                            v-on="on"
                            >{{
                              truncator(demoQuote["Dirección de origen"], 300)
                            }}</span
                          >
                        </template>
                        <span>{{ demoQuote["Dirección de origen"] }}</span>
                      </v-tooltip>
                    </span>
                    <span>
                      <span class="font-weight-bold success--text"
                        >{{ $t("parcel_quote_sender") }}:</span
                      >
                      <span>
                        {{
                          createTripForm.iSend === "1"
                            ? demoQuote["Usuario responsable"]
                            : demoQuote["Usuario tercero"]
                        }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              class="mb-1"
                              v-bind="attrs"
                              v-on="on"
                              >{{ icons.mdiInformationOutline }}</v-icon
                            >
                          </template>
                          <span>{{ $t("person_is_contact") }}</span>
                        </v-tooltip>
                      </span>
                    </span>
                    <span>
                      <span class="font-weight-bold success--text"
                        >{{ $t("parcel_quote_sender_phone") }}:</span
                      >
                      <span>
                        {{
                          createTripForm.iSend === "1"
                            ? demoQuote["Usuario responsable teléfono"]
                            : demoQuote["Usuario tercero teléfono"]
                        }}
                      </span>
                    </span>
                    <span class="text-justify">
                      <span class="font-weight-bold success--text"
                        >{{ $t("parcel_quote_instructions_on_origin") }}:</span
                      >
                      <span class="text-justify" style="white-space: pre-wrap">
                        {{ paquete.textarea1 }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
                <!-- destination quote -->
                <v-row
                  dense
                  class="
                    d-flex
                    flex-row
                    align-center
                    justify-center
                    text-sm
                    border-bottom-gray
                    mt-2
                  "
                  ><v-col
                    cols="1"
                    class="d-flex flex-row align-center justify-center"
                  >
                    <v-img
                      :src="
                        require('../../../assets/IconDestinationMapMap.png')
                      "
                      contain
                      max-width="40"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col
                    cols="11"
                    class="d-flex flex-column align-start justify-center"
                  >
                    <!-- origen -->
                    <span class="text-justify">
                      <span class="font-weight-bold error--text"
                        >{{ $t("parcel_quote_destination") }}:</span
                      >
                      <v-tooltip top max-width="300" :disabled="!false">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="ml-1 text-justify"
                            v-bind="attrs"
                            v-on="on"
                            >{{
                              truncator(demoQuote["Dirección de destino"], 300)
                            }}</span
                          >
                        </template>
                        <span>{{ demoQuote["Dirección de destino"] }}</span>
                      </v-tooltip>
                    </span>
                    <span>
                      <span class="font-weight-bold error--text"
                        >{{ $t("parcel_quote_receiver") }}:</span
                      >
                      <span>
                        {{
                          createTripForm.iSend === "1"
                            ? demoQuote["Usuario tercero"]
                            : demoQuote["Usuario responsable"]
                        }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              small
                              class="mb-1"
                              v-bind="attrs"
                              v-on="on"
                              >{{ icons.mdiInformationOutline }}</v-icon
                            >
                          </template>
                          <span>{{ $t("person_is_contact") }}</span>
                        </v-tooltip>
                      </span>
                    </span>
                    <span>
                      <span class="font-weight-bold error--text"
                        >{{ $t("parcel_quote_recoiver_phone") }}:</span
                      >
                      <span>
                        {{
                          createTripForm.iSend === "1"
                            ? demoQuote["Usuario tercero teléfono"]
                            : demoQuote["Usuario responsable teléfono"]
                        }}
                      </span>
                    </span>
                    <span class="text-justify">
                      <span class="font-weight-bold error--text"
                        >{{
                          $t("parcel_quote_instructions_on_destination")
                        }}:</span
                      >
                      <span class="text-justify" style="white-space: pre-wrap">
                        {{ paquete.textarea2 }}
                      </span>
                    </span>
                  </v-col>
                </v-row>

                <!-- paquete foto detalles -->
                <v-row dense class="border-bottom-gray mt-2">
                  <v-col
                    cols="12"
                    class="d-flex flex-column justify-center align-center"
                  >
                    <span class="text--primary fontUrbanist font-weight-bold">{{
                      $t("parcel_quote_package_photo")
                    }}</span>
                    <v-dialog
                      v-if="paquete.image"
                      v-model="dialogStep3"
                      fullscreen
                      transition="dialog-bottom-transition"
                      scrollable
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-fade-transition mode="out-in">
                          <v-img
                            :src="paquete.imageUrl && paquete.imageUrl"
                            width="70"
                            height="70"
                            v-bind="attrs"
                            v-on="on"
                            class="hover-image rounded-lg"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-fade-transition>
                      </template>
                      <v-card class="testcard" style="overflow-y: auto">
                        <v-row dense>
                          <v-col cols="12" class="">
                            <v-toolbar
                              color="primary"
                              class="d-flex justify-space-between"
                              tile
                            >
                              <span
                                class="d-flex flex-row justify-space-between"
                                style="width: 98vw"
                              >
                                <v-toolbar-title class="color-white-text">{{
                                  $t("parcel_package_image")
                                }}</v-toolbar-title>
                                <v-btn icon dark @click="dialogStep3 = false">
                                  <v-icon>{{ icons.mdiClose }}</v-icon>
                                </v-btn>
                              </span>
                            </v-toolbar>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col
                            cols="12"
                            class="d-flex align-start justify-center pa-12"
                          >
                            <v-img
                              :src="paquete.imageUrl && paquete.imageUrl"
                              class="modal-image"
                              contain
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12">
                    <blockquote class="text-justify text-sm">
                      <span class="font-weight-bold primary--text"
                        >{{ $t("parcel_quote_package_description") }}:</span
                      >
                      <span class="text-sm">
                        {{ paquete.textareaPackage }}
                      </span>
                    </blockquote>
                  </v-col>
                </v-row>

                <!-- total a pagar y surge -->
                <v-row dense class="mt-2 text-sm mb-6">
                  <v-col cols="12">
                    <span class="font-weight-bold primary--text">
                      {{ $t("Parcel Type") }}:
                    </span>
                    <span> {{ $t(createTripForm.tripType) }} </span>
                  </v-col>
                  <v-col cols="12">
                    <span class="font-weight-bold primary--text">
                      {{ $t("Date and Time") }}:
                    </span>
                    <span> {{ demoQuote["Fecha y hora"] }}</span>
                  </v-col>
                  <v-col cols="12" class="mb-2">
                    <span class="font-weight-bold primary--text">
                      {{ $t("Sobredemanda") }}:
                    </span>
                    <span> {{ demoQuote["Sobredemanda"] }} </span>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex flex-row justify-center align-center"
                  >
                    <span>
                      <v-icon color="primary">
                        {{ icons.mdiMapMarkerDistance }}
                      </v-icon>
                    </span>
                    <span class="ml-1"> {{ demoQuote["DISTANCIA"] }} </span>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex flex-row justify-center align-center"
                  >
                    <span>
                      <v-icon color="primary">
                        {{ icons.mdiClockOutline }}
                      </v-icon>
                    </span>
                    <span class="ml-1"> {{ demoQuote["DURACIÓN"] }} </span>
                  </v-col>
                </v-row>
              </template>
            </span>

            <!-- Botones -->
            <span>
              <v-row dense>
                <v-col cols="12">
                  <template v-if="step === 1">
                    <!-- cotizacion rapida -->
                    <v-btn
                      v-if="createTripForm.tripType === 'Quick quote'"
                      color="primary"
                      block
                      @click="handleStep1QuickQuote()"
                      :disabled="
                        invalid || !Boolean(stateDestinationAddressMap)
                      "
                    >
                      {{ $t("Get quick quote") }}
                    </v-btn>
                    <!-- programado o normal -->
                    <v-btn
                      v-else
                      color="primary"
                      block
                      @click="handleStepUpNewParcel()"
                      :disabled="
                        invalid ||
                        (createTripForm.tripType === 'Parcel now'
                          ? !Boolean(stateServiceTypeOrigin) ||
                            !Boolean(stateDestinationAddressMap)
                          : !Boolean(stateDestinationAddressMap))
                      "
                    >
                      {{ $t("parcel_continue") }}
                    </v-btn>
                  </template>
                  <!-- step 2  -->
                  <template v-if="step === 2">
                    <!-- cotizacion rapida -->
                    <template v-if="createTripForm.tripType === 'Quick quote'">
                      <span
                        class="color-red-text"
                        :class="
                          $vuetify.breakpoint.lgAndUp
                            ? 'text-body-2'
                            : 'text-caption'
                        "
                        v-if="createTripForm.tripType !== 'Quick quote'"
                      >
                        <span
                          class="font-weight-bold"
                          v-if="computedDisabledCreateTripNote"
                          >Nota:
                        </span>
                        <span
                          v-if="computedDisabledCreateTripNote"
                          v-html="computedDisabledCreateTripNote"
                        >
                        </span>
                      </span>
                      <div
                        class="
                          background-green
                          color-blue-text
                          pa-2
                          font-weight-bold
                          d-flex
                          justify-space-between
                        "
                      >
                        <span
                          >TOTAL<span>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on"> * </span>
                              </template>
                              <span>{{ $t("variable_tariffs") }}</span>
                            </v-tooltip></span
                          >:</span
                        >
                        <span>{{ demoQuote["TOTAL"] }}</span>
                      </div>
                      <div
                        v-if="
                          createTripForm.tripType !== 'Quick quote' &&
                          !computedDisabledCreateTripNote
                        "
                      >
                        <v-checkbox v-model="paquete.checkbox">
                          <template v-slot:label>
                            <div class="text-caption">
                              <span v-if="$vuetify.lang.current === 'es'">
                                He leído y estoy de acuerdo con las
                                <a
                                  href="/parcel-guide"
                                  target="_blank"
                                  @click.stop
                                >
                                  Políticas</a
                                >
                                de envíos de
                                <span
                                  class="font-weight-bold fontUrbanist text-lg"
                                  >Ridery</span
                                >
                              </span>
                              <span v-else>
                                I have read and I agree to the
                                <a
                                  href="/parcel-guide"
                                  target="_blank"
                                  @click.stop
                                >
                                  Policies
                                </a>
                                related to parcels from
                                <span
                                  class="font-weight-bold fontUrbanist text-lg"
                                  >Ridery</span
                                >
                              </span>
                            </div>
                          </template>
                        </v-checkbox>
                      </div>
                      <v-btn
                        color="primary"
                        outlined
                        block
                        @click="handleStepBack()"
                        class="mt-4"
                      >
                        {{ $t("GoBack") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mt-2"
                        block
                        @click="handleCreate()"
                        v-if="createTripForm.tripType !== 'Quick quote'"
                        :disabled="
                          computedDisabledCreateTrip || !paquete.checkbox
                        "
                      >
                        {{ $t("parcel_create") }}
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-row dense class="d-flex flex-row align-center">
                        <v-col cols="6" class="pr-2">
                          <v-btn
                            color="primary"
                            outlined
                            @click="handleStepBack()"
                            block
                          >
                            {{ $t("GoBack") }}
                          </v-btn>
                        </v-col>
                        <v-col cols="6" class="pl-2">
                          <v-btn
                            color="primary"
                            @click="handleStepUpNewParcel()"
                            block
                            :disabled="invalid"
                          >
                            {{ $t("parcel_continue") }}
                          </v-btn></v-col
                        >
                      </v-row>
                    </template>
                  </template>

                  <!-- step 3 -->
                  <template v-if="step === 3">
                    <v-row dense class="d-flex flex-row align-center">
                      <v-col cols="6" class="pr-2">
                        <v-btn
                          color="primary"
                          outlined
                          block
                          @click="handleStepBack()"
                        >
                          {{ $t("GoBack") }}
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="pl-2">
                        <v-btn
                          color="primary"
                          block
                          @click="handleStepUpNewParcel()"
                          :disabled="
                            invalid ||
                            !Boolean(paquete.image) ||
                            !Boolean(paquete.imageUrl)
                          "
                        >
                          {{ $t("parcel_continue") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>

                  <!-- step 4 -->
                  <template v-if="step === 4">
                    <!-- datos de envio -->
                    <v-row dense class="d-flex flex-row align-center">
                      <v-col cols="6" class="pr-2">
                        <v-btn
                          color="primary"
                          outlined
                          block
                          @click="handleStepBack()"
                        >
                          {{ $t("GoBack") }}
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="pl-2">
                        <v-btn
                          color="primary"
                          block
                          @click="handleStepUpNewParcelStep5()"
                          :disabled="!paquete.checkbox"
                        >
                          {{ $t("parcel_continue") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>

                  <!-- step 5 -->
                  <template v-if="step === 5">
                    <span
                      class="color-red-text"
                      :class="
                        $vuetify.breakpoint.lgAndUp
                          ? 'text-body-2'
                          : 'text-caption'
                      "
                      v-if="createTripForm.tripType !== 'Quick quote'"
                    >
                      <span
                        class="font-weight-bold"
                        v-if="computedDisabledCreateTripNote"
                        >Nota:
                      </span>
                      <span
                        v-if="computedDisabledCreateTripNote"
                        v-html="computedDisabledCreateTripNote"
                        class="text-justify"
                      >
                      </span>
                    </span>
                    <div
                      class="
                        background-green
                        color-blue-text
                        pa-2
                        font-weight-bold
                        d-flex
                        justify-space-between
                        mb-4
                      "
                    >
                      <span
                        >TOTAL<span>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on"> * </span>
                            </template>
                            <span>{{ $t("variable_tariffs") }}</span>
                          </v-tooltip></span
                        >:</span
                      >
                      <span>{{ demoQuote["TOTAL"] }}</span>
                    </div>
                    <v-row dense class="d-flex flex-row align-center mb-4">
                      <v-col cols="6" class="pr-2">
                        <v-btn
                          color="primary"
                          outlined
                          block
                          @click="handleStepBack()"
                        >
                          {{ $t("GoBack") }}
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="pl-2">
                        <v-btn
                          color="primary"
                          block
                          :disabled="Boolean(computedDisabledCreateTripNote)"
                          @click="handleCreate()"
                        >
                          {{ $t("parcel_create") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </span>
          </v-form>
        </ValidationObserver>
      </span>
    </v-card>
  </v-col>
</template>
<script>
import {
  mdiWindowClose,
  mdiMapMarker,
  mdiCalendar,
  mdiClockOutline,
  mdiPhoneOutline,
  mdiEmailOutline,
  mdiAccount,
  mdiBagSuitcase,
  mdiRefresh,
  mdiInformationOutline,
  mdiPackageVariantClosed,
  mdiClose,
  mdiSwapHorizontal,
  mdiMapMarkerDistance,
} from "@mdi/js";
import DateTimePicker from "vuetify-datetime-picker";
import { mapActions, mapState } from "vuex";
import { gmapApi } from "vue2-google-maps";
import PhoneInput from "@/components/PhoneInput";
import axios from "@/plugins/axios";
import Modal from "./Modal.vue";
import ModalEN from "./ModalEN.vue";
import errorCode from "@/api/errorCode";
import { truncator } from "@/utils";
import {
  getRouteDistanceAndDurationOpenStreet,
  getRouteDistanceAndDurationGoogleMaps,
} from "@/api/timeDistanceCalculation";

export default {
  components: { DateTimePicker, PhoneInput },
  props: {
    formOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorCode: errorCode,
      icons: {
        mdiWindowClose,
        mdiMapMarker,
        mdiCalendar,
        mdiClockOutline,
        mdiPhoneOutline,
        mdiEmailOutline,
        mdiAccount,
        mdiBagSuitcase,
        mdiRefresh,
        mdiInformationOutline,
        mdiPackageVariantClosed,
        mdiClose,
        mdiSwapHorizontal,
        mdiMapMarkerDistance,
      },
      createTripForm: {
        tripType: "Parcel now",
        tripTypeOptions: ["Parcel now", "Scheduled", "Quick quote"],
        originAddress: null,
        destinationAddress: null,
        userType: "Corporate",
        userTypeOptions: ["Corporate"],
        serviceType: null,
        serviceTypeOptions: [],
        userID: null,
        userFullName: null,
        phonePrefixList: ["0412", "0414", "0424", "0416", "0426"],
        phonePrefix: null,
        phone: null,
        email: null,
        originAddressMap: null,
        destinationAddressMap: null,
        corporateUsers: [],
        isCorporateWallet: false,
        iSend: "1", //1 yo envio, 2 recibo yo
      },
      dateTimePickerProps: {
        nullDatetime: null,
        datetime: new Date(),
        datetimeString: "2019-01-01 12:00",
        formattedDatetime: "09/01/2019 12:00",
        textFieldProps: {
          appendIcon: "mdi-event",
          dense: true,
          outlined: true,
        },
        dateProps: {
          // headerColor: "red",
          min: "",
          max: "",
        },
        timeProps: {
          format: "ampm",
        },
      },
      step: 1,
      demoQuote: {
        "Tipo de envío": "Programado",
        "Fecha y hora": "17/03/2022 05:12 PM",
        "Dirección de origen":
          "Calle París, Caracas, Distrito Capital, Venezuela",
        "Dirección de destino": "Metrobus La Salle, Caracas 1050",
        "Tipo de usuario": "Corporativo",
        "Nombre y apellido": "Carlos Perez",
        "Tipo de servicio": "Carro",
        DISTANCIA: "5.6 KM",
        DURACIÓN: "12 min",
        TOTAL: "$5",
      },
      address: null,
      distance: null,
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      serviceTypeIdName: {},
      estimatedFare: "",
      surgeMultiplier: "",
      isSurgeHours: "",
      city: "",
      scheduleDate: "",
      overdraftUser: null,
      corporateWalletUser: null,
      paquete: {
        textarea1: "",
        textarea2: "",
        textareaPackage: "",
        checkbox: false,
        image: null,
        imageUrl: null,
      },
      dialogStep3: false,
    };
  },
  methods: {
    truncator,
    getRouteDistanceAndDurationOpenStreet,
    getRouteDistanceAndDurationGoogleMaps,
    ...mapActions([
      "setLoading",
      "setOrigin",
      "setDestination",
      "setServiceTypeOrigin",
      "setDraggable",
    ]),
    ...mapActions("auth", ["meNotLoading"]),

    handleBurntDescription(type) {
      switch (type) {
        case "Envío comfort":
          return {
            volume: "88 cm x 69 cm x 26 cm",
            mass: "60 Kg. máx",
            examples:
              "Ej. – 2 equipajes medianos + morral pequeño, 9 bolsas de supermercado, cava mediana, 2 cajas de harina de maíz de 20 empaques.",
          };
        case "Envío Económico":
          return {
            volume: "69 cm x 44 cm x 26 cm",
            mass: "40 Kg. máx",
            examples:
              "Ej. – 1 equipaje mediano + morral pequeño, 7 bolsas de supermercado, cava pequeña, 1 caja de harina de maíz de 20 empaques.",
          };
        case "Envío Moto":
          return {
            volume: "36 cm x 33 cm x 26 cm",
            mass: "4 Kg. máx",
            examples:
              "Ej. - resma de papel, repuestos pequeños, casco de moto, llaves.",
          };
        default:
          break;
      }
    },

    handleErrorCoder(object, val) {
      return Object.keys(object).find((key) => object[key] === `${val}`);
    },
    handleClick() {
      this.$emit("emitClick");
      this.step = 1;
      this.resetForm();
    },
    handleStepBack() {
      this.step--;
      this.setDraggable(true);
    },
    async handleStep1QuickQuote() {
      if (this.createTripForm.tripType === "Quick quote") {
        this.setLoading(true);
        await this.meNotLoading();
        try {
          const goodDistanceTripCalculation = await this.getDistanceTrip();
          if (goodDistanceTripCalculation) {
            await this.getQuote();
          }
        } catch (error) {
          this.$dialog.notify.error(error.message);
        } finally {
          this.setLoading(false);
        }
      }
    },
    async handleStepUpNewParcel() {
      this.step++;
      this.setDraggable(true);
    },
    async getQuote() {
      try {
        // let validHour = !this.handleValidHour();

        // let validSchedule = !this.handleValidHour(this.computedDateQuote); deprecado
        let validHour = true;
        let validSchedule = true;
        if (validSchedule) {
          const { data } = await axios.post(
            `${process.env.VUE_APP_API_URL}/fare_estimate`,
            {
              latitude: `${this.stateOriginAddressMap.geometry.location.lat()}`,
              longitude: `${this.stateOriginAddressMap.geometry.location.lng()}`,
              dest_latitude: `${this.stateDestinationAddressMap.geometry.location.lat()}`,
              dest_longitude: `${this.stateDestinationAddressMap.geometry.location.lng()}`,
              distance: this.distance,
              duration: this.duration,
              service_type_id:
                this.stateServiceTypeOrigin &&
                this.stateServiceTypeOrigin[0].type
                  ? this.stateServiceTypeOrigin[0].type
                  : this.createTripForm.serviceType,
              time: this.computedDateQuote,
              timezone: this.computedTimezone,
            }
          );
          if (!!data.success) {
            if (this.createTripForm.tripType === "Quick quote") {
              var quote = {
                "Tipo de envío": this.createTripForm.tripType,
                "Fecha y hora": this.$moment(this.computedDateQuote)
                  .locale("es")
                  .format("DD-MM-YYYY h:mm a"),
                "Dirección de origen":
                  this.stateOriginAddressMap.formatted_address,
                "Dirección de destino":
                  this.stateDestinationAddressMap.formatted_address,
                "Tipo de servicio":
                  this.serviceTypeIdName[this.createTripForm.serviceType],
                Sobredemanda: Boolean(data.is_surge_hours)
                  ? `${data.surge_multiplier}x`
                  : "No",
                DISTANCIA: data.distance + "Km",
                DURACIÓN: Math.ceil(data.time) + "m",
                TOTAL: "$" + data.estimated_fare,
              };
            } else {
              var quote = {
                "Tipo de envío": this.createTripForm.tripType,
                "Fecha y hora": this.$moment(this.computedDateQuote)
                  .locale("es")
                  .format("DD-MM-YYYY h:mm a"),
                "Dirección de origen":
                  this.stateOriginAddressMap.formatted_address,
                "Dirección de destino":
                  this.stateDestinationAddressMap.formatted_address,
                "Usuario responsable": this.createTripForm.corporateUsers.find(
                  (u) => u._id === this.createTripForm.userID
                ).fullName,
                "Usuario responsable teléfono":
                  this.createTripForm.corporateUsers.find(
                    (u) => u._id === this.createTripForm.userID
                  ).phone,
                "Usuario tercero": this.createTripForm.userFullName,
                "Usuario tercero teléfono":
                  this.createTripForm.phonePrefix + this.createTripForm.phone,
                "Tipo de servicio":
                  this.serviceTypeIdName[this.createTripForm.serviceType],
                parcel_textarea: this.paquete.textarea,
                Sobredemanda: Boolean(data.is_surge_hours)
                  ? `${data.surge_multiplier}x`
                  : "No",
                DISTANCIA: data.distance + "Km",
                DURACIÓN: Math.ceil(data.time) + "m",
                TOTAL: "$" + data.estimated_fare,
              };
            }
            this.overdraftUser =
              this.createTripForm.userType === "Corporate" &&
              this.createTripForm.tripType !== "Quick quote" &&
              this.createTripForm.corporateUsers.find(
                (u) => u._id === this.createTripForm.userID
              ).overdraft;
            this.corporateWalletUser =
              this.createTripForm.userType === "Corporate" &&
              this.createTripForm.tripType !== "Quick quote" &&
              this.createTripForm.corporateUsers.find(
                (u) => u._id === this.createTripForm.userID
              ).wallet;
            this.estimatedFare = data.estimated_fare;
            this.surgeMultiplier = data.surge_multiplier;
            this.isSurgeHours = data.is_surge_hours;
            this.demoQuote = quote;
            this.step++;
            this.setDraggable(false);
          }
        } else {
          throw new Error("Invalid hour");
        }
      } catch (error) {
        console.log(error);
        if (error.message === "Invalid hour") {
          this.$dialog.notify.error(
            "El horario establecido para envíos es de Lunes a Viernes: 8:30 am – 5:30 pm Hora de Venezuela"
          );
        } else {
          this.$dialog.notify.error("Quote error");
        }
      }
    },
    async handleCreate() {
      if (this.createTripForm.userType === "Corporate") {
        this.handleCheck(this.createTripForm.userID);
      }
      if (this.createTripForm.userType === "Not corporate") {
        this.handleCheck();
      }
    },
    async handleCheck(id) {
      //corporate
      this.setLoading(true);
      try {
        let params = {};
        if (id) {
          params["user_id"] = id;
        }
        if (!id) {
          params = {
            user_id: "",
            first_name: this.createTripForm.userFirstName,
            last_name: this.createTripForm.userLastName,
            email: this.createTripForm.email,
            is_new_user: 1,
            phone:
              this.createTripForm.phonePrefix.substring(1) +
              this.createTripForm.phone,
            country_phone_code: this.countryPhoneCode,
            city: this.city, //ajustar con respuesta de service type
            country: this.countryName,
          };
        }
        //validamos usuario
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/checkuser_corporate`,
          params
        );
        // si el usuario es valido
        if (data.success) {
          if (!id) {
            this.createTripForm.userID = data.user._id;
          }
          // subimos la foto del paquete
          let formData = new FormData();
          formData.append(
            "user_picture",
            this.paquete.image,
            this.paquete.image.name
          );
          formData.append("directory_id", 15);
          try {
            const { data } = await axios.post(
              `${process.env.VUE_APP_STAGING}/upload_picture`,
              formData
            );
            if (!!data.success && data.picture) {
              let pictureBucketUrl = data.picture;
              //intentamos crear el viaje
              try {
                let params_create = {
                  is_corporate_wallet:
                    this.createTripForm.userType === "Corporate" &&
                    this.createTripForm.isCorporateWallet,
                  // is_corporate_wallet: 1,
                  is_panel_new: 1,
                  user_id: this.createTripForm.userID,
                  service_type_id: this.createTripForm.serviceType,
                  timezone: "America/Caracas", //ajustar
                  latitude: `${this.stateOriginAddressMap.geometry.location.lat()}`,
                  longitude: `${this.stateOriginAddressMap.geometry.location.lng()}`,
                  fixed_price: parseFloat(this.estimatedFare),
                  trip_type: 7,
                  user_type_id: this._id,
                  distance: this.distance,
                  time: this.duration,
                  source_address: this.stateOriginAddressMap.formatted_address,
                  destination_address:
                    this.stateDestinationAddressMap.formatted_address,
                  d_latitude: `${this.stateDestinationAddressMap.geometry.location.lat()}`,
                  d_longitude: `${this.stateDestinationAddressMap.geometry.location.lng()}`,
                  is_panel_trip: 1,
                  surge_multiplier: this.surgeMultiplier,
                  is_surge_hours: this.isSurgeHours,
                  is_fixed_fare: 1,
                  payment_mode: 10,
                  is_scheduled_trip:
                    this.createTripForm.tripType === "Scheduled" ? 1 : 0,
                  start_time:
                    this.createTripForm.tripType === "Scheduled"
                      ? Math.round(
                          new Date(
                            this.dateTimePickerProps.datetime
                          ).getTime() - new Date().getTime()
                        )
                      : undefined,
                  // shipment: {
                  //   responsible_first_name: this.createTripForm.userFirstName,
                  //   responsible_last_name: this.createTripForm.userLastName,
                  //   responsible_phone:
                  //     this.createTripForm.phonePrefix.substring(1) +
                  //     this.createTripForm.phone,
                  //   package_type: "",
                  //   package_description: this.paquete.textarea,
                  //   package_weight: "",
                  // },
                  is_shipment: 1,
                  responsible_full_name: this.createTripForm.userFullName,
                  responsible_phone:
                    this.createTripForm.phonePrefix.substring(1) +
                    this.createTripForm.phone,
                  package_description: this.paquete.textareaPackage,
                  source_instructions: this.paquete.textarea1,
                  destination_instructions: this.paquete.textarea2,
                  user_picture: pictureBucketUrl,
                  is_user_sender: this.createTripForm.iSend === "1" ? 1 : 0,
                };
                const { data } = await axios.post(
                  `${process.env.VUE_APP_STAGING}/createtrip`,
                  params_create
                );
                if (!!data) {
                  if (data.success) {
                    this.$emit("tripCreated");
                    this.$emit("emitClick");
                    this.$dialog.notify.success(this.$t("parcel_created"));
                    this.resetForm();
                  } else {
                    if (data.error_code) {
                      let key = this.handleErrorCoder(
                        this.errorCode,
                        data.error_code
                      );
                      throw new Error(
                        `Error in Parcel ${data.error_code}: ${key}, ID:${this.unique_id}`
                      );
                    } else {
                      throw new Error(
                        `Error in Parcel Creation, ID:${this.unique_id}`
                      );
                    }
                  }
                }
              } catch (error) {
                this.$dialog.notify.error(error.message);
              }
            } else {
              throw new Error("Error uploading picture");
            }
          } catch (error) {
            throw new Error("Error uploading picture");
          }
        }
        if (!data.success) {
          if (id) {
            throw new Error(
              `Error checkuser: Corporate IDUSER: ${data.user.unique_id}, IDCORP: ${this.unique_id}`,
              data.trip
                ? this.handleErrorCauseCheckUser(data)
                : {
                    cause: {
                      properCause: "ULTIMO_VIAJE_PENDIENTE_POR_CALIFICACION",
                    },
                  }
            );
          } else {
            throw new Error(
              `Error checkuser: Not Corporate IDUSER: ${data.user.unique_id}, IDCORP: ${this.unique_id}`,
              data.trip
                ? this.handleErrorCauseCheckUser(data)
                : {
                    cause: {
                      properCause: "ULTIMO_VIAJE_PENDIENTE_POR_CALIFICACION",
                    },
                  }
            );
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
        error.cause?.tripID
          ? this.$dialog.notify.error(
              `IDTRIP: ${error.cause?.tripID || "N/A"}, ${
                error.cause.properCause
              }`
            )
          : error.cause
          ? this.$dialog.notify.error(`${error.cause?.properCause}`)
          : null;
      } finally {
        this.setLoading(false);
      }
    },
    resetForm() {
      this.step = 1;

      // datos user
      this.createTripForm.tripType = "Parcel now";
      this.createTripForm.userType = "Corporate";
      this.createTripForm.tripTypeOptions = [
        "Parcel now",
        "Scheduled",
        "Quick quote",
      ];
      this.createTripForm.serviceType = null;
      this.createTripForm.userID = null;
      this.createTripForm.userFullName = null;
      this.createTripForm.phonePrefix = null;
      this.createTripForm.phone = null;
      this.createTripForm.email = null;
      this.corporateUsers = [];
      this.createTripForm.isCorporateWallet = false;
      this.createTripForm.iSend = "1";

      // datos mapa
      this.createTripForm.originAddress = null;
      this.createTripForm.originAddressMap = null;
      this.createTripForm.destinationAddress = null;
      this.createTripForm.destinationAddressMap = null;
      this.address = null;
      this.setOrigin(null);
      this.setDestination(null);
      this.setServiceTypeOrigin(null);
      this.distance = null;
      this.duration = null;
      this.setDraggable(true);

      this.paquete.textarea1 = "";
      this.paquete.textarea2 = "";
      this.paquete.textareaPackage = "";
      this.paquete.checkbox = false;
      this.paquete.image = null;
      this.paquete.imageUrl = null;

      //hora
      this.dateTimePickerProps.datetime = null;
    },
    geoCodeLocationOrigin(place) {
      let self = this;
      let geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ placeId: place.place_id }, async (results, status) => {
        try {
          self.setLoading(true);
          if (status == (await google.maps.GeocoderStatus.OK)) {
            let address = results[0];
            !!address && (self.address = address);
            if (self.address) {
              self.address.formatted_address =
                place.name + ", " + self.address.formatted_address;
              self.setOrigin(self.address);
              self.createTripForm.originAddressMap = self.address;
              self.address = null;
            }
          }
          self.setLoading(false);
        } catch (error) {
          console.log(error);
        }
      });
    },
    async geoCodeLocationDestination(place) {
      let self = this;
      let geocoder = new this.google.maps.Geocoder();
      await geocoder.geocode({ placeId: place.place_id }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          let address = results[0];
          !!address && (self.address = address);
          if (self.address) {
            self.address.formatted_address =
              place.name + ", " + self.address.formatted_address;
            self.setDestination(self.address);
            self.createTripForm.destinationAddressMap = self.address;
            self.address = null;
          }
        } else {
          console.log(status);
        }
      });
    },
    setPlaceOrigin(place) {
      if (!!place) {
        this.geoCodeLocationOrigin(place);
      }
    },
    async setPlaceDestination(place) {
      if (!!place) {
        this.setLoading(true);
        await this.geoCodeLocationDestination(place);
        this.setLoading(false);
      }
    },
    async getServiceType() {
      this.setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/typelist_for_dispatcher`,
          {
            subAdminCountry: this.countryName,
            latitude: `${this.stateOriginAddressMap.geometry.location.lat()}`,
            longitude: `${this.stateOriginAddressMap.geometry.location.lng()}`,
          },
          {
            params: {
              only_shipments: 1,
            },
          }
        );
        if (!!data.success) {
          if (Boolean(data.city_detail.shipments_allowed)) {
            this.createTripForm.serviceTypeOptions = [];
            data.citytypes.forEach((type) => {
              this.createTripForm.serviceTypeOptions.push({
                type: type.type_details.typename,
                image: type.type_details.type_image_url,
                baggage: type.baggages,
                capacity: type.max_space,
                _id: type._id,
                type_details: type.type_details,
                sequence_no: type.type_details.sequence_no,
              });
            });
            this.createTripForm.serviceTypeOptions.sort(
              (a, b) => a.sequence_no - b.sequence_no
            );
            data.citytypes.forEach((type) => {
              this.serviceTypeIdName[`${type._id}`] = type.type_details;
            });
            this.city = data.city_detail.cityname;
            if (!Boolean(data.city_detail.scheduled_shipments_allowed)) {
              if (this.createTripForm.tripType === "Scheduled") {
                this.$dialog.notify.warning(
                  "Esta ciudad no dispone de envío programado"
                );
                this.createTripForm.tripType = "Parcel now";
              }
              this.createTripForm.tripTypeOptions = [
                "Parcel now",
                "Quick quote",
              ];
            } else {
              this.createTripForm.tripTypeOptions = [
                "Parcel now",
                "Scheduled",
                "Quick quote",
              ];
            }
          } else {
            this.createTripForm.originAddress = null;
            this.setServiceTypeOrigin(null);
            throw new Error(
              this.$t(
                "Lo sentimos, Ridery no presta servicio de envío en esa dirección de origen"
              )
            );
          }
        } else {
          this.createTripForm.serviceTypeOptions = [];
          if (data.error_code === "1002") {
            this.createTripForm.originAddress = null;
            this.setServiceTypeOrigin(null);
            throw new Error(
              this.$t(
                "Lo sentimos, Ridery no presta servicio en esa dirección de origen"
              )
            );
          }
          throw data;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async getCorporateUsers() {
      this.setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/users`
        );
        if (!!data) {
          this.createTripForm.corporateUsers = [];
          data.user_list.forEach((user) => {
            Boolean(user.is_approved) &&
              this.createTripForm.corporateUsers.push({
                fullName: `${user.first_name} ${user.last_name}`,
                _id: user._id,
                phone: user.phone,
                initials: user.first_name[0] + user.last_name[0],
                email: user.email,
                overdraft: user.is_allow_overdraft,
                wallet: user.corporate_wallet_limit,
                picture: user.picture,
              });
          });
          this.createTripForm.corporateUsers.sort((a, b) => {
            return a.fullName.localeCompare(b.fullName);
          });
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async getDistanceTrip() {
      this.distance = null;
      this.duration = null;
      const origin = {
        lat: parseFloat(this.stateOriginAddressMap.geometry.location.lat()),
        lng: parseFloat(this.stateOriginAddressMap.geometry.location.lng()),
      };
      const destination = {
        lat: parseFloat(
          this.stateDestinationAddressMap.geometry.location.lat()
        ),
        lng: parseFloat(
          this.stateDestinationAddressMap.geometry.location.lng()
        ),
      };
      try {
        if (this.is_used_google_maps_service) {
          const { distance, duration } =
            await getRouteDistanceAndDurationGoogleMaps(
              origin,
              destination,
              this.google
            );
          this.distance = distance;
          this.duration = duration;
          return true;
        } else {
          const { distance, duration } =
            await getRouteDistanceAndDurationOpenStreet(origin, destination);
          this.distance = distance;
          this.duration = duration;
          return true;
        }
      } catch (error) {
        console.info(error);
        this.$dialog.notify.error(error.message);
        return false;
      }
    },
    async getServiceTypeDrivers() {
      let origin = {
        lat: parseFloat(this.stateOriginAddressMap.geometry.location.lat()),
        lng: parseFloat(this.stateOriginAddressMap.geometry.location.lng()),
      };
      this.setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/get_nearby_provider`,
          {
            service_type_id: this.createTripForm.serviceType,
            latitude: origin.lat,
            longitude: origin.lng,
          }
        );
        if (!!data.success) {
          let providers_ = [];
          data.providers.forEach((provider) => {
            provider.service_type.forEach((type) => {
              if (type === this.createTripForm.serviceType) {
                providers_.push({
                  type: type,
                  bearing: provider.bearing,
                  picture: provider.picture,
                  lat: parseFloat(provider.providerLocation[0]),
                  lng: parseFloat(provider.providerLocation[1]),
                });
              }
            });
          });
          this.setServiceTypeOrigin(providers_);
        } else {
          if (this.createTripForm.tripType === "Parcel now") {
            this.setServiceTypeOrigin(null);
            this.showModal();
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async showModal() {
      const params = {
        fullscreen: false,
        width: !this.$vuetify.breakpoint.mdAndUp ? "80%" : "40%",
        transition: "dialog-top-transition",
      };

      if (this.$vuetify.lang.current === "es") {
        await this.$dialog.showAndWait(Modal, params);
      } else {
        await this.$dialog.showAndWait(ModalEN, params);
      }
    },
    capitalizeFirstLetter: (str) => {
      return str ? str[0].toUpperCase() + str.slice(1) : "";
    },
    handleFilterAutocompleteCorporateUsers(_item, _queryText, _itemText) {
      const searchText = _queryText.toLowerCase();

      const fullName = _item.fullName.toLowerCase();
      const phone = _item.phone.toLowerCase();
      const email = _item.email.toLowerCase();

      return (
        fullName.indexOf(searchText) > -1 ||
        phone.indexOf(searchText) > -1 ||
        email.indexOf(searchText) > -1
      );
    },
    handleValidHour(hour) {
      if (hour) {
        const userTodayOffset = new Date().getTimezoneOffset() / 60;
        const vzlaOffset = 4;
        const realOffset = vzlaOffset - userTodayOffset;
        let hr = hour.getHours();
        let min = hour.getMinutes();
        let day = hour.getDay();
        if (day === 0) day = 7;
        // console.log(
        //   "🚀 ~ file: FormPart.vue ~ line 1614 ~ handleValidHour ~ day",
        //   day
        // );
        let hrOffset = hr + realOffset;
        // lunes a sabado
        if (1 <= day && day <= 5) {
          if (8 <= hrOffset && hrOffset <= 17) {
            if (hrOffset === 8 && min < 30) {
              return true;
            }
            if (hrOffset === 17 && min > 30) {
              return true;
            }
            return false;
          }
        }
        // sabado a domingo
        // if (6 <= day <= 7) {
        //   if (8 <= hrOffset && hrOffset <= 17) {
        //     if (hrOffset === 18 && min > 0) {
        //       return true;
        //     }
        //     return false;
        //   }
        // }
        return true;
      } else {
        let today = new Date();
        const userTodayOffset = new Date().getTimezoneOffset() / 60;
        const vzlaOffset = 4;
        const realOffset = vzlaOffset - userTodayOffset;
        let hr = today.getHours();
        let min = today.getMinutes();
        let day = today.getDay();
        if (day === 0) day = 7;
        let hrOffset = hr + realOffset;
        // lunes a sabado
        if (1 <= day && day <= 5) {
          if (7 <= hrOffset && hrOffset <= 18) {
            if (hrOffset === 18 && min > 0) {
              return true;
            }
            return false;
          }
        }
        // sabado a domingo
        // if (6 <= day <= 7) {
        //   if (8 <= hrOffset && hrOffset <= 17) {
        //     if (hrOffset === 17 && min > 0) {
        //       return true;
        //     }
        //     return false;
        //   }
        // }
        return true;
      }
    },
    handleErrorCauseCheckUser({ trip }) {
      let cause = {
        cause: { tripID: trip.unique_id, properCause: "ERROR_CHECKUSER" },
      };
      if (trip.is_trip_completed && !trip.payment_status) {
        cause.cause.properCause = "VIAJE_PENDIENTE_POR_PAGO";
        return cause;
      }
      if (!trip.is_trip_completed && !trip.payment_status) {
        cause.cause.properCause = "VIAJE_EN_CURSO";
        return cause;
      }
      if (trip.is_trip_completed && !trip.is_provider_rated) {
        cause.cause.properCause = "FALTA_CALIFICACION_VIAJE";
        return cause;
      }
    },
    handleClickUpload() {
      this.$refs.file.$refs.input.click();
    },
    previewImage(e) {
      if (this.paquete.image && this.paquete.image.size) {
        let size = this.paquete.image.size / 1024 / 1024;
        if (size <= 5) {
          this.paquete.imageUrl = URL.createObjectURL(this.paquete.image);
          return;
        }
        this.$dialog.notify.error(this.$t("parcel_image_error_size"));
        this.paquete.image = null;
        this.paquete.imageUrl = null;
        return;
      }
      this.$dialog.notify.error(this.$t("parcel_image_error_required"));

      this.paquete.image = null;
      this.paquete.imageUrl = null;
    },
    async handleStepUpNewParcelStep5() {
      this.setLoading(true);
      await this.meNotLoading();
      try {
        const goodDistanceTripCalculation = await this.getDistanceTrip();
        if (goodDistanceTripCalculation) {
          await this.getQuote();
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.setLoading(false);
      }
    },
    handleAlternate() {
      if (this.createTripForm.iSend === "1") {
        this.createTripForm.iSend = "2";
        return;
      }
      if (this.createTripForm.iSend === "2") {
        this.createTripForm.iSend = "1";
        return;
      }
    },
  },
  created() {
    let plusOne = this.dateTimePickerProps.datetime;
    plusOne.setMinutes(plusOne.getMinutes() + this.pre_time);
    this.dateTimePickerProps.datetime = plusOne;
    this.scheduleDate = plusOne;
    // this.dateTimePickerProps.timeProps.min = plusOne;
    let today = new Date();
    this.dateTimePickerProps.dateProps.min = this.$moment(today)
      .add(-4, "hours")
      .toISOString();
    this.dateTimePickerProps.dateProps.max = this.$moment(today)
      .add(-4, "hours")
      .add(15, "days")
      .toISOString();
    // this.resetForm();
    this.meNotLoading();
    this.getCorporateUsers();

    // console.info(this.errorCode['ERROR_CODE_NOT_GET_YOUR_DETAIL'])
    // console.info(this.handleErrorCoder(this.errorCode, 995))
  },
  watch: {
    "createTripForm.originAddress": function (newVal) {
      if (!newVal) {
        this.createTripForm.originAddress = null;
        this.createTripForm.originAddressMap = null;
        this.setOrigin(null);
      }
    },
    stateOriginAddressMap: async function () {
      if (this.stateOriginAddressMap && this.stateOriginAddressMap.geometry) {
        this.createTripForm.originAddress =
          this.stateOriginAddressMap.formatted_address;
        this.createTripForm.serviceType = null;
        await this.getServiceType(this.stateOriginAddressMap.geometry.location);
      }
    },
    "createTripForm.destinationAddress": function (newVal) {
      if (!newVal) {
        this.createTripForm.destinationAddress = null;
        this.createTripForm.destinationAddressMap = null;
        this.setDestination(null);
        // this.createTripForm.serviceType = null;
        // this.createTripForm.serviceTypeOptions = [];
      }
    },
    stateDestinationAddressMap: function (_newVal) {
      if (
        this.stateDestinationAddressMap &&
        this.stateDestinationAddressMap.geometry
      ) {
        this.createTripForm.destinationAddress =
          this.stateDestinationAddressMap.formatted_address;
      }
      if (!_newVal) {
        this.createTripForm.destinationAddress = null;
      }
    },
    "createTripForm.serviceType": function (newVal) {
      //recibir conductores cercanos según la flota y ubicacion
      if (newVal) {
        this.getServiceTypeDrivers();
      } else {
        this.setServiceTypeOrigin(null);
      }
    },
    "dateTimePickerProps.datetime": function (newVal) {
      if (newVal && newVal < this.scheduleDate) {
        this.$dialog.notify.warning(
          this.$t(
            "You've selected an invalid hour, previous to current time. Please select a different hour"
          )
        );
        let plusOne = new Date();
        plusOne.setMinutes(plusOne.getMinutes() + this.pre_time);
        this.dateTimePickerProps.datetime = plusOne;
        this.scheduleDate = plusOne;
      } else if (!newVal) {
        let plusOne = new Date();
        plusOne.setMinutes(plusOne.getMinutes() + this.pre_time);
        this.dateTimePickerProps.datetime = plusOne;
        this.scheduleDate = plusOne;
      }
    },
    "createTripForm.userFirstName": function (newVal) {
      this.createTripForm.userFirstName = this.capitalizeFirstLetter(newVal);
    },
    "createTripForm.userLastName": function (newVal) {
      this.createTripForm.userLastName = this.capitalizeFirstLetter(newVal);
    },
    "paquete.textarea": function (newVal) {
      this.paquete.textarea = this.capitalizeFirstLetter(newVal);
    },
    step: function (newVal, oldVal) {
      if (newVal === 1) {
        this.setDraggable(true);
        return;
      }
      this.setDraggable(false);
    },
    "createTripForm.userFullName": function (newVal) {
      this.createTripForm.userFullName = this.capitalizeFirstLetter(newVal);
    },
  },
  computed: {
    ...mapState([
      "stateOriginAddressMap",
      "stateDestinationAddressMap",
      "stateServiceTypeOrigin",
    ]),
    ...mapState("auth", [
      "countryName",
      "countryPhoneCode",
      "_id",
      "pre_time",
      "wallet",
      "unique_id",
      "is_used_google_maps_service",
    ]),

    handleColorClose() {
      return this.$vuetify.theme.dark
        ? "color: #ffffff !important;"
        : "color: #3a3541 !important;";
    },
    google: gmapApi,
    computedDateQuote() {
      if (this.createTripForm.tripType === "Parcel now") {
        return new Date();
      } else {
        return this.dateTimePickerProps.datetime;
      }
    },
    computedTimezone() {
      // let today = new Date()
      // return this.$moment.utc(today).valueOf();
      return "UTC";
    },
    computedDisabledCreateTrip() {
      if (this.step === 5) {
        if (
          this.createTripForm.userType === "Corporate" &&
          !this.createTripForm.isCorporateWallet
        ) {
          if (
            this.corporateWalletUser < parseFloat(this.estimatedFare) &&
            !this.overdraftUser
          ) {
            return true;
          }
          if (
            this.corporateWalletUser < parseFloat(this.estimatedFare) &&
            this.overdraftUser &&
            parseFloat(this.estimatedFare) > this.wallet
          ) {
            return true;
          }
        } else {
          if (parseFloat(this.estimatedFare) > this.wallet) {
            return true;
          }
        }
        return false;
      }
    },
    computedDisabledCreateTripNote() {
      if (this.step === 5) {
        if (
          this.createTripForm.userType === "Corporate" &&
          !this.createTripForm.isCorporateWallet
        ) {
          if (
            this.corporateWalletUser < parseFloat(this.estimatedFare) &&
            !this.overdraftUser
          ) {
            return `el usuario <span class="font-weight-bold text-decoration-underline">no</span> posee saldo corporativo suficiente ($${parseFloat(
              this.corporateWalletUser
            ).toFixed(
              2
            )}) <span class="font-weight-bold text-decoration-underline">ni</span> posee sobregiro`;
          }
          if (
            this.corporateWalletUser < parseFloat(this.estimatedFare) &&
            this.overdraftUser &&
            parseFloat(this.estimatedFare) > this.wallet
          ) {
            return `el usuario <span class="font-weight-bold text-decoration-underline">no</span> posee saldo corporativo suficiente ($${parseFloat(
              this.corporateWalletUser
            ).toFixed(
              2
            )}), posee sobregiro, pero el panel corporativo <span class="font-weight-bold text-decoration-underline">no</span> dispone de suficiente balance para crear el viaje ($${
              this.wallet
            })`;
          }
        } else {
          if (parseFloat(this.estimatedFare) > this.wallet) {
            return (
              `El panel corporativo <span class="font-weight-bold text-decoration-underline">no</span> dispone de suficiente balance para crear el envío` +
              " " +
              `($${this.wallet})`
            );
          }
        }
        return false;
      }
    },
    computedCurrentCorporateUser() {
      return this.createTripForm.corporateUsers.find(
        (user) => user._id === this.createTripForm.userID
      );
    },
    computedSenderButton() {
      let name =
        this.createTripForm.iSend === "1"
          ? this.computedCurrentCorporateUser.fullName
          : this.createTripForm.userFullName;
      return this.truncator(name, 22);
    },
    computedReceiverButton() {
      let name =
        this.createTripForm.iSend === "1"
          ? this.createTripForm.userFullName
          : this.computedCurrentCorporateUser.fullName;
      return this.truncator(name, 22);
    },
  },
};
</script>
<style lang="scss" scoped>
.margin {
  margin-top: -0.29rem !important;
}
.buttons {
  // position: absolute;
  // margin-bottom: -4rem;
}
.fontUrbanist {
  font-family: "Urbanist", sans-serif !important;
}
.test {
  &.v-tooltip__content {
    background: transparent;
    opacity: 1 !important;
  }
}
.modal-image {
  max-width: 70vw;
  max-height: 70vw;
  // max-height: 500px;
  // max-width: 800px;
}
.testcard {
  // max-height: 100vh;
}

.hover-image:hover {
  cursor: pointer;
}

.prohibited-text {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.shipment-summary {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
}
.border-bottom-gray {
  border-bottom: 1px solid #898989;
}

.toggleUpDown {
  transition: transform 0.3s ease-in-out !important;
}

.toggleUpDown.rotate {
  transform: rotate(180deg);
}
.flex-color {
  transition: background-color 0.3s ease-in-out !important;
}

.toggleUpOpacity {
  -webkit-animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
