<template>
  <v-row dense>
    <!-- map -->

    <MapPart
      :formOpen="formOpen"
      @emitClick="emitClick('formPart')"
      id="mapPart"
      @refreshMap="handleRefreshMap()"
      :refreshTable="refreshTable"
    />
    <!-- form -->
    <v-fade-transition mode="out-in">
      <FormPart
        :formOpen="formOpen"
        @emitClick="emitClick('mapPart')"
        @tripCreated="tripCreated()"
        id="formPart"
      />
    </v-fade-transition>
  </v-row>
</template>

<script>
import MapPart from "./parts/MapPart.vue";
import FormPart from "./parts/FormPart.vue";

export default {
  components: { MapPart, FormPart },
  props: {
    refreshTable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      formOpen: false,
    };
  },
  methods: {
    tripCreated() {
      this.$emit("tripCreated");
    },
    async emitClick(place) {
      this.formOpen = !this.formOpen;
      if (!this.$vuetify.breakpoint.mdAndUp) {
        await new Promise((r) => setTimeout(r, 1));
        let options = {
          easing: "ease-in",
          offset: 0,
          force: true,
          x: false,
          y: true,
        };

        this.$scrollTo(`#${place}`, 500, options);
      }
    },
    handleRefreshMap() {
      this.$emit("refreshMap");
    },
  },
};
</script>

<style lang="scss" scoped></style>
