<template>
  <v-card tile class="modal">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="fill-height text-center">
      <v-container>
        <h2>¡Lo sentimos!</h2>
        <span>
          <p class="mt-5 subheading">
            En este momento no hay conductores cercanos a la dirección de
            origen.
          </p>
          <p class="subheading">
            Ponte en contacto con nuestros agentes de post-venta para que te
            apoyen.
          </p>
          <!-- <v-btn
            color="info"
            :href="`mailto:${admin_email}`"
            target="_blank"
          >
            <v-icon style="color: #ffffff !important">
              {{ icons.mdiEmailOutline }}
            </v-icon>
            <span class="ml-2"> Email </span>
          </v-btn> -->
          <v-btn
            color="success"
            href="https://api.whatsapp.com/send?phone=584128835418&text=Soy%20un%20cliente%20corporativo.%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC"
            target="_blank"
          >
            <!-- class="ml-2"
            :class="!$vuetify.breakpoint.mdAndUp && 'mt-2'" -->
            <v-icon style="color: #ffffff !important">
              {{ icons.mdiWhatsapp }}
            </v-icon>
            <span class="ml-2"> Whatsapp </span>
          </v-btn>
        </span>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp, mdiEmailOutline } from "@mdi/js";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiEmailOutline,
      },
    };
  },
  computed: {
    ...mapState("auth", ["admin_phone", "admin_email"]),
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
    // color: white
    // opacity: 1
    color: rgba(58, 53, 65, 0.54)
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 100vh

a
  text-decoration: none
</style>
