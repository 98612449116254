<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5">
          <!-- Titulo -->
          <v-row dense>
            <v-col cols="12" class="d-flex flex-row justify-space-between">
              <span class="d-flex flex-row justify-start align-center">
                <h2>{{ $t("Current and scheduled parcel") }}</h2>
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      medium
                      v-bind="attrs"
                      v-on="on"
                      class="ml-6 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    <!-- <ul> -->
                    <!-- <li> -->
                    {{
                      $t(
                        "parcel_table_running_tooltip"
                      )
                    }}
                    <!-- </li> -->
                    <!-- </ul> -->
                  </span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <!-- tabla -->
          <v-row dense class="">
            <v-col cols="12" v-if="table.full">
              <DataTable
                :loading="table.tableLoading"
                :headers="table.headers"
                :items="computedTrips"
                @cancelledTrip="handleCancelled()"
                @refresh="handleRefresh()"
              />
            </v-col>
            <v-col v-else class="">
              <div class="flex opacity-60">
                <v-img
                  src="../../assets/paper-map-cuate.png"
                  width="350"
                  contain
                  v-if="!$vuetify.theme.isDark"
                ></v-img>
                <v-img
                  src="../../assets/paper-map-cuate_dark.png"
                  width="350"
                  contain
                  v-else
                ></v-img>
                <!-- <p class="color-blue-text mt-4">
                  Comienza a crear viajes y monitorealos aquí
                </p> -->
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiRefresh,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";
import DataTable from "./parts/DataTable.vue";
export default {
  components: {
    DataTable,
  },
  props: {
    trip: {
      type: Boolean,
      required: false,
    },
    refreshMap: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMagnify,
        mdiCalendar,
        mdiDotsVertical,
        mdiRefresh,
      },
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      new_: false,
      table: {
        trips: [],
        tableLoading: null,
        headers: [
          {
            text: "PARCEL ID",
            value: "unique_id",
            align: "center",
            sortable: false,
          },
          {
            text: "USER",
            value: "user_name",
            align: "start",
            sortable: false,
          },
          {
            text: "PROVIDER",
            value: "provider_name",
            align: "start",
            sortable: false,
          },
          {
            text: "DATE AND TIME",
            value: "date",
            align: "start",
            sortable: false,
            width: 180,
          },
          { text: "COST", value: "total", align: "end", sortable: false },
          {
            text: "SERVICIO",
            value: "serviceType",
            align: "center",
            sortable: false,
          },
          {
            text: "ORIGIN ADDRESS",
            value: "source_address",
            align: "start",
            sortable: false,
            width: 180,
          },
          {
            text: "DESTINATION ADDRESS",
            value: "destination_address",
            align: "start",
            sortable: false,
            width: 180,
          },
          {
            text: "STATUS",
            value: "trip_status",
            align: "center",
            sortable: true,
          },
          {
            text: "ACTION",
            value: "actions",
            sortable: false,
            align: "center",
          },
          // { text: "", value: "data-table-expand" },
        ],
        full: true,
      },
    };
  },
  computed: {
    ...mapState("auth", ["_id"]),
    computedTrips() {
      return this.table.trips;
    },
  },
  created() {
    this.getRecentTrips();
  },
  methods: {
    async getRecentTrips() {
      this.table.tableLoading = true;
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/recent_trips`, { params: {only_shipments: 1}}
        );
        if (!!data.success) {
          this.table.trips = [];
          data.trips.forEach((trip) => {
            this.table.trips.push(trip);
          });
          this.table.trips.length === 0
            ? (this.table.full = false)
            : (this.table.full = true);
        } else {
          throw new Error("Error getting recent trips");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.table.tableLoading = false;
    },
    handleCancelled() {
      this.getRecentTrips();
    },
    handleRefresh() {
      this.getRecentTrips();
      this.$emit("refreshTable");
    },
  },
  watch: {
    trip: function () {
      this.getRecentTrips();
    },
    refreshMap: function () {
      this.getRecentTrips();
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
