<template>
  <v-fade-transition mode="out-in">
    <v-col :md="formOpen ? 8 : 12" cols="12">
      <v-card class="pa-5" :min-height="computedHeight">
        <v-row dense>
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-row justify-md-start justify-center"
          >
            <span class="d-flex flex-row justify-start align-center">
              <h2>
                {{ $t("Realtime map") }}
              </h2>
              <v-tooltip top :max-width="300">
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    medium
                    v-on="on"
                    class="ml-6 color-gray-text rounded-0"
                  >
                    {{ icons.mdiInformationOutline }}
                  </v-icon>
                </template>
                <span v-if="!formOpen">
                  <!-- <ul> -->
                  <!-- <li> -->
                  {{
                    $t(
                      "In this view you can visualize the parcels in course on the map"
                    )
                  }}
                  <!-- </li> -->
                  <!-- </ul> -->
                </span>
                <span v-if="formOpen">
                  <ul>
                    <li>
                      {{
                        $t(
                          "In this map view you can visualize the waypoints origin and destination"
                        )
                      }}
                    </li>
                    <li>
                      {{
                        $t(
                          "Also driver's availability near the origin point of the parcel"
                        )
                      }}
                    </li>
                  </ul>
                </span>
              </v-tooltip>
              <span class="ml-4" v-if="!formOpen">
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="handleRefreshMap"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon medium>
                        {{ icons.mdiRefresh }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    <!-- <ul> -->
                    <!-- <li> -->
                    {{ $t("By clicking here you can refresh the map") }}
                    <!-- </li> -->
                    <!-- </ul> -->
                  </span>
                </v-tooltip>
              </span>
            </span>
          </v-col>
          <v-col
            v-if="!formOpen"
            cols="12"
            md="6"
            class="d-flex justify-md-end justify-center"
          >
            <v-tooltip top :disabled="!false">
              <template v-slot:activator="{ on, attrs }">
                <span class="" v-bind="attrs" v-on="on">
                  <v-btn
                    class=""
                    rounded
                    color="success"
                    @click.stop="handleClick()"
                    :disabled="false"
                  >
                    <span class="d-flex flex-row justify-center align-center">
                      <v-icon style="color: #ffffff !important">{{
                        icons.mdiPackageVariantClosed
                      }}</v-icon>
                      <span class="ml-2">
                        {{ $t("New Parcel") }}
                      </span>
                    </span>
                  </v-btn>
                </span>
              </template>
              <span v-if="$vuetify.lang.current === 'es'">
                El horario para solicitar envíos es de:
                <br />
                <ul>
                  <li>Lunes a Viernes: 8:30 am – 5:30 pm</li>
                  <li>Zona horaria de Venezuela</li>
                </ul>
              </span>
              <span v-else>
                The schedule to request parcels is from:
                <br />
                <ul>
                  <li>Monday to Friday: 8:30 am – 5:30 pm</li>
                  <li>Venezuela's timezone</li>
                </ul>
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <GoogleMap
              :formOpen="formOpen"
              :refreshMap="refreshMap"
              :refreshTable="refreshTable"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-fade-transition>
</template>
<script>
import GoogleMap from "@/views/Parcel/components/Map/parts/GoogleMap.vue";

import {
  mdiInformationOutline,
  mdiRefresh,
  mdiPackageVariantClosed,
} from "@mdi/js";
import trip from "@/assets/Trip_white.svg";
import { gmapApi } from "vue2-google-maps";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    GoogleMap,
  },
  props: {
    formOpen: {
      type: Boolean,
      default: false,
    },
    refreshTable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiRefresh,
        mdiPackageVariantClosed,
      },
      trip: { trip },
      refreshMap: false,
      timeInvalid: false,
      ccsPolyLine: [
        { lng: -67.0615768, lat: 10.6332776 },
        { lng: -66.9482803, lat: 10.6319279 },
        { lng: -66.883049, lat: 10.6326027 },
        { lng: -66.8322372, lat: 10.6356395 },
        { lng: -66.8047714, lat: 10.6261915 },
        { lng: -66.7615128, lat: 10.5887339 },
        { lng: -66.7350769, lat: 10.5630846 },
        { lng: -66.701088, lat: 10.5289948 },
        { lng: -66.6890717, lat: 10.5006399 },
        { lng: -66.6801453, lat: 10.4722825 },
        { lng: -66.7137909, lat: 10.4405461 },
        { lng: -66.7539597, lat: 10.4169105 },
        { lng: -66.7930984, lat: 10.3939485 },
        { lng: -66.8456268, lat: 10.3780767 },
        { lng: -66.9232178, lat: 10.3554496 },
        { lng: -66.9520569, lat: 10.3270789 },
        { lng: -67.0014954, lat: 10.3081637 },
        { lng: -67.0729065, lat: 10.3081637 },
        { lng: -67.1003723, lat: 10.3486949 },
        { lng: -67.0948792, lat: 10.4216378 },
        { lng: -67.0797729, lat: 10.4770089 },
        { lng: -67.0729065, lat: 10.5337203 },
        { lng: -67.0674133, lat: 10.5836717 },
        { lng: -67.0619202, lat: 10.6255166 },
        { lng: -67.0616627, lat: 10.6330245 },
        { lng: -67.0615661, lat: 10.6332248 },
        { lng: -67.0615768, lat: 10.6332776 },
      ],
    };
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setOrigin",
      "setDestination",
      "setServiceTypeOrigin",
      "setDraggable",
    ]),
    handleClick() {
      this.$emit("emitClick");
    },
    handleRefreshMap() {
      this.refreshMap = !this.refreshMap;
      this.$emit("refreshMap");
    },
    handleValidHour() {
      let today = new Date();
      const userTodayOffset = new Date().getTimezoneOffset() / 60;
      const vzlaOffset = 4;
      const realOffset = vzlaOffset - userTodayOffset;
      let hr = today.getHours();
      let min = today.getMinutes();
      let day = today.getDay();
      if (day === 0) day = 7;
      let hrOffset = hr + realOffset;
      // lunes a sabado
      if (1 <= day && day <= 5) {
        if (8 <= hrOffset && hrOffset <= 17) {
          if (hrOffset === 8 && min < 30) {
            return true;
          }
          if (hrOffset === 17 && min > 30) {
            return true;
          }
          return false;
        }
      }
      // sabado a domingo
      // if (6 <= day <= 7) {
      //   if (8 <= hrOffset && hrOffset <= 17) {
      //     if (hrOffset === 17 && min > 0) {
      //       return true;
      //     }
      //     return false;
      //   }
      // }
      return true;
    },
  },
  computed: {
    ...mapState([
      "stateBaseLocationMap",
      "stateOriginAddressMap",
      "stateDestinationAddressMap",
      "stateServiceTypeOrigin",
      "iconsDraggable",
    ]),
    google: gmapApi,
    computedHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.formOpen ? "660.5px" : "500px";
      } else {
        return "400px";
      }
    },
  },
  created() {
    this.timeInvalid = this.handleValidHour();
    // this.timeInvalid = !this.handleValidHour();
  },
  watch: {
    // stateDestinationAddressMap: {
    //   handler(_newVal, _oldVal) {
    //     if (_newVal) {
    //       const coords = new this.google.maps.LatLng(
    //         this.stateDestinationAddressMap.geometry.location.lat(),
    //         this.stateDestinationAddressMap.geometry.location.lng()
    //       );
    //       const ccsPolyLineMap = new this.google.maps.Polygon({
    //         paths: this.ccsPolyLine,
    //       });
    //       const inside = this.google.maps.geometry.poly.containsLocation(
    //         coords,
    //         ccsPolyLineMap
    //       );

    //       if (!inside) {
    //         this.$dialog.notify.error(
    //           "El servicio de envíos sólo está disponible dentro de Caracas"
    //         );
    //         this.setDestination(null);
    //       }
    //     }
    //   },
    //   deep: true,
    // },
  },
};
</script>
<style lang="scss" scoped>
.test2 {
  // position: relative;
}

.test3 {
  // z-index: 999;
  position: absolute;
  transform: translateX(125%);
  transform: translateX(370%);
}
.test4 {
  margin-right: 1900px;
}

.test {
  // position: absolute;
  // right: -5vw;
  // left: 0
  // transform: translateX(50%);
  // background: red;
}
.icon-white {
  color: #ffffff !important;
  max-width: 30px !important;
}
</style>
