var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('v-col',{attrs:{"md":_vm.formOpen ? 8 : 12,"cols":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"min-height":_vm.computedHeight}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("Realtime map"))+" ")]),_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text rounded-0",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[(!_vm.formOpen)?_c('span',[_vm._v(" "+_vm._s(_vm.$t( "In this view you can visualize the parcels in course on the map" ))+" ")]):_vm._e(),(_vm.formOpen)?_c('span',[_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.$t( "In this map view you can visualize the waypoints origin and destination" ))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t( "Also driver's availability near the origin point of the parcel" ))+" ")])])]):_vm._e()]),(!_vm.formOpen)?_c('span',{staticClass:"ml-4"},[_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.handleRefreshMap}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)]}}],null,false,2506938641)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("By clicking here you can refresh the map"))+" ")])])],1):_vm._e()],1)]),(!_vm.formOpen)?_c('v-col',{staticClass:"d-flex justify-md-end justify-center",attrs:{"cols":"12","md":"6"}},[_c('v-tooltip',{attrs:{"top":"","disabled":!false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"rounded":"","color":"success","disabled":false},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick()}}},[_c('span',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-icon',{staticStyle:{"color":"#ffffff !important"}},[_vm._v(_vm._s(_vm.icons.mdiPackageVariantClosed))]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t("New Parcel"))+" ")])],1)])],1)]}}],null,false,1819994997)},[(_vm.$vuetify.lang.current === 'es')?_c('span',[_vm._v(" El horario para solicitar envíos es de: "),_c('br'),_c('ul',[_c('li',[_vm._v("Lunes a Viernes: 8:30 am – 5:30 pm")]),_c('li',[_vm._v("Zona horaria de Venezuela")])])]):_c('span',[_vm._v(" The schedule to request parcels is from: "),_c('br'),_c('ul',[_c('li',[_vm._v("Monday to Friday: 8:30 am – 5:30 pm")]),_c('li',[_vm._v("Venezuela's timezone")])])])])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('GoogleMap',{attrs:{"formOpen":_vm.formOpen,"refreshMap":_vm.refreshMap,"refreshTable":_vm.refreshTable}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }